import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';

import ListItem from './Item';
import { Container, Header, ActionButton, Empty } from './styles';

import { FiFileText } from 'react-icons/fi';

const AccordeonList = ({ data }) => {
	const { search } = useLocation();

	const [collapseAll, setCollapseAll] = useState(false);
	const [filter, setFilter] = useState(undefined);

	useEffect(() => {
		const { status } = querystring.parse(search);

		switch (status) {
			case '1':
				return setFilter('vigente(s)');
			case '2':
				return setFilter('encerrado(s)');
			case '0':
				return setFilter('pendente(s)');
			default:
				return setFilter('');
		}
	}, [search]);

	return (
		<Container>
			<Header>
				<div className='buttons'>
					<ActionButton onClick={() => setCollapseAll(true)}>Mostrar Todos</ActionButton>
					<ActionButton onClick={() => setCollapseAll(false)}>
						Esconder Todos
					</ActionButton>
				</div>

				<span>
					Você possui <strong>{data.length}</strong> contrato(s) {filter}
				</span>
			</Header>

			{data.length ? (
				data.map((item) => (
					<ListItem key={item._id} defaultOpen={!collapseAll} data={item} />
				))
			) : (
				<Empty>
					<FiFileText />
					<p>Você não possui contratos {filter}</p>
				</Empty>
			)}
		</Container>
	);
};

export default AccordeonList;
