import React, { useState, useEffect, useRef, useCallback } from 'react';
import { format, addDays } from 'date-fns';

import Button from '../Button';
import ProgressBar from '../ProgressBar';
import UploadedFile from './UploadedFile';
import { Heading2 } from '../Typography';
import { notify } from '../Notification';
import {
	Container,
	Column,
	UploadContainer,
	PercentageBar,
	UploadsList,
	EmptyList,
	PercentageIndicator,
	ProgressBarWrapper,
} from './styles';

import { FaCloudUploadAlt } from 'react-icons/fa';
import { MdCheckCircle } from 'react-icons/md';

const VoicesUploader = ({
	modalRef,
	reset,
	date,
	uploadsList,
	contract,
	uploadsNeeded,
	pickFiles,
	removeFile,
	program,
}) => {
	const inputRef = useRef(null);
	const [percentage, setPercentage] = useState(0);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fallback, setFallback] = useState(false);

	const handleInputChange = useCallback(
		(e) => {
			const files = [...e.target.files];
			const uploadedFilesQuantity = uploadsList.length;
			const newFilesQuantity = files.length;

			if (uploadedFilesQuantity + newFilesQuantity > uploadsNeeded) {
				return notify('warn', 'Você selecionou mais arquivos do que pede o modelo');
			}

			let haveInvalidFile = false;
			const newFiles = [];

			files.forEach((file) => {
				if (!file.type.startsWith('audio/')) {
					haveInvalidFile = true;
				} else {
					newFiles.push(file);
				}
			});

			/**
			 * removing files that are already in uploads list
			 */
			const newFilesWithoutDuplicates = newFiles.filter((file) => {
				let isDuplicate = false;

				uploadsList.forEach((uploadedFile) => {
					if (
						uploadedFile.name === file.name &&
						uploadedFile.size === file.size &&
						uploadedFile.type === file.type
					) {
						isDuplicate = true;
					}
				});

				return !isDuplicate;
			});

			if (haveInvalidFile) {
				notify('warn', 'Ops, algum arquivo foi enviado com o formato inválido');
			}

			if (newFilesWithoutDuplicates.length !== newFiles.length) {
				/** it means that some of the files selected is already in uploads list */
				notify('warn', 'Hmmm, parece que você selecionou arquivos repetidos');
			}

			inputRef.current.value = '';
			return pickFiles([...uploadsList, ...newFilesWithoutDuplicates]);
		},
		[pickFiles, uploadsList, uploadsNeeded]
	);

	const renderList = useCallback(() => {
		if (!uploadsList.length) {
			return <EmptyList>Selecione os arquivos</EmptyList>;
		}

		return (
			<UploadsList>
				{uploadsList.map((uploadedFile, index) => (
					<UploadedFile
						key={index}
						index={index}
						fileSrc={uploadedFile}
						removeFile={removeFile}
					/>
				))}
			</UploadsList>
		);
	}, [uploadsList, removeFile]);

	const handleSubmit = useCallback(async () => {
		try {
			if (uploadsList.length < uploadsNeeded) {
				return notify(
					'warn',
					`Você precisa selecionar mais ${uploadsNeeded - uploadsList.length} arquivos`
				);
			}

			setFallback(true);

			const payload = new FormData();
			const dateRelease = format(addDays(new Date(date), 1), 'dd/MM/yyyy');

			payload.append('program', contract.program._id);
			payload.append('dateRelease', dateRelease);
			uploadsList.forEach((uploadedFile) => payload.append('files', uploadedFile));

			const token = localStorage.getItem('@AUTH') || sessionStorage.getItem('@AUTH');
			const request = new XMLHttpRequest();

			request.open('POST', `${process.env.REACT_APP_BASE_URL}/api/v2/offs`);
			request.setRequestHeader('Authorization', `Bearer ${token}`);
			request.responseType = 'json';

			request.upload.addEventListener(
				'progress',
				({ loaded, total, lengthComputable }) => {
					if (lengthComputable) {
						setUploadProgress(Math.floor((loaded * 100) / total));
					}
				},
				false
			);

			request.onloadend = () => {
				const { offs } = request.response;

				setTimeout(() => {
					reset(offs.length);
					setUploadProgress(0);
					setFallback(false);

					modalRef.current.close();
				}, 1000);

				setTimeout(() => notify('success', 'Seus offs foram enviados com sucesso'), 1300);
			};

			request.send(payload);
		} catch (error) {
			console.log(error);
			setFallback(false);
			notify('warn', error?.response?.data?.message);
		}
	}, [contract, date, modalRef, reset, uploadsList, uploadsNeeded]);

	useEffect(() => {
		if (uploadsNeeded) {
			const uploadedFiles = uploadsList.length;
			const percentage = Math.round((uploadedFiles * 100) / uploadsNeeded);

			setPercentage(percentage);
		}
	}, [uploadsList, uploadsNeeded]);

	return (
		<Container>
			<header>
				<Heading2>
					Subir Vozes <span>({program})</span>
				</Heading2>
			</header>

			<div className='content'>
				<div className='upload-area'>
					<Column left>
						<UploadContainer
							htmlFor='offsInput'
							disabled={uploadsList.length === uploadsNeeded || !date}>
							<FaCloudUploadAlt />

							<span>Clique aqui para subir os arquivos</span>
							<input
								id='offsInput'
								type='file'
								ref={inputRef}
								accept='audio/mp3'
								onChange={handleInputChange}
								multiple
							/>
						</UploadContainer>
					</Column>

					<Column right>{renderList()}</Column>
				</div>

				{!!uploadProgress ? (
					<ProgressBarWrapper>
						<ProgressBar progress={uploadProgress} label='subindo offs...' />
					</ProgressBarWrapper>
				) : (
					<PercentageBar percentage={percentage}>
						<div className='text'>
							<p>
								Esse modelo utiliza <strong>{uploadsNeeded}</strong> arquivos de voz
							</p>
						</div>
						<div className='percentage'>
							<div></div>
							<PercentageIndicator percentage={percentage}>
								{percentage === 100 ? (
									<>
										Prontinho, já pode subir suas vozes <MdCheckCircle />
									</>
								) : (
									`${uploadsList.length}/${uploadsNeeded}`
								)}
							</PercentageIndicator>
						</div>
					</PercentageBar>
				)}
			</div>

			{!uploadProgress && (
				<div className='buttons'>
					<Button
						color='#5f5f5f'
						variant='outlined'
						disabled={fallback}
						onClick={() => modalRef.current.close()}>
						Cancelar
					</Button>
					<Button color='success' onClick={handleSubmit} loading={fallback}>
						Enviar Vozes
					</Button>
				</div>
			)}
		</Container>
	);
};

export default VoicesUploader;
