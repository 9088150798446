import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 100%;

	@media (max-width: 645px) {
		padding: 0 12px;

		& > ul {
			margin-bottom: 0;
		}
	}
`;

export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 12px;

	@media (max-width: 1045px) {
		grid-template-columns: 1fr;
		gap: 4px 0;
	}
`;

export const Column = styled.div`
	max-width: 100%;
	display: grid;
	gap: 12px;
	grid-template-columns: 1fr;
	align-content: flex-start;
`;

export const SubContainer = styled.div`
	width: 100%;
	height: max-content;
	background: var(--white);
	border-radius: var(--border-radius-1);
	border: 1px solid var(--card-border);
	box-shadow: 0px 4px 5px 2px rgba(200, 200, 200, 0.1);
	padding: 16px;

	& > div.header {
		width: 100%;
		display: grid;
		grid-template-columns: 2fr 3fr;
		gap: 0 12px;
	}
`;

export const ProgramDetails = styled.article`
	height: 100%;
	display: flex;
	flex-direction: column;

	& > span.empty {
		width: 100%;
		height: 100%;
		min-height: 64px;
		padding: 2px 8px;
		font-size: 16px;
		font-style: italic;
		color: var(--grey-1);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& > ul {
		width: 100%;

		& > li {
			padding: 12px 0;
			border-bottom: 1px solid #dedede;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 15px;

			& * {
				color: var(--body-1);
			}

			& > span {
				text-align: start;
			}

			& > strong {
				text-align: end;
			}
		}

		& > li:last-child {
			border: none;
		}

		@media (max-width: 512px) {
			& > li {
				flex-direction: column;
				align-items: flex-start;
			}

			& > li:last-child {
				flex-direction: row;
			}
		}
	}
`;

export const AbleToGeneratePlaylists = styled.span`
	font-size: 14px;
	font-weight: 600;
	color: var(--white) !important;
	padding: 4px 12px;
	border-radius: var(--border-radius-1);
	max-width: max-content;
	background: ${({ able }) => (able ? 'var(--success)' : 'var(--danger)')};
`;

export const ModalContent = styled.div`
	width: 1100px;
	max-width: 95vw;
	max-height: 95vh;
  overflow-y: auto;
	background: var(--white);
	box-shadow: var(--modal-box-shadow);
	border-radius: var(--border-radius-1);

	& > header {
		width: 100%;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--input-border-hover);

		& > h3 {
			margin: 0;
			font-size: 18px;
			color: var(--heading-1);
		}
	}

	& > form {
		width: 100%;
		padding: 12px;
	}

	& > div.buttons {
		border-top: 1px solid var(--input-border);
		grid-column: span 4;
		padding: 6px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& > button ~ button {
			margin-left: 8px;
		}

		@media (max-width: 645px) {
			& > button:first-child {
				display: none;
			}
		}
	}
`;

export const DownloadPlaylistButton = styled.a`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 0;
	border: 1px solid var(--primary);
	border-radius: var(--border-radius-1);
	transition: background-color 300ms;

	grid-column: span 4;

	&:hover {
		background: #ffc2001c;
	}

	& > span {
		font-size: 16px;
		color: var(--primary);
		font-weight: 600;
	}

	& > svg {
		font-size: 18px;
		color: var(--primary);
		margin-right: 12px;
	}
`;

export const SendButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > div {
		width: 100%;
	}

	& > div.playlist-buttons {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 0 8px;

		@media (max-width: 512px) {
			grid-template-columns: 1fr;
			gap: 8px 0;
		}
	}
`;

export const OpenUploadButtonContainer = styled.div`
	width: 100%;
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid var(--input-border);

	& > button {
		width: 100%;
	}
`;

export const SeeObservations = styled.button`
	background: none;
	color: var(--primary) !important;
	border: 1px solid var(--primary);
	padding: 4px 12px;
	border-radius: var(--border-radius-1);
	cursor: pointer;
	font-size: 14px;
	transition: 300ms;
	font-weight: 600;

	&:hover {
		background: var(--primary);
		color: var(--white) !important;
	}
`;

export const NoObservations = styled.span`
	font-size: 15px;
	color: var(--body-2);
	font-style: italic;
`;

export const ObservationsModalContent = styled.div`
	width: 500px;
	max-width: 90vw;
	border-radius: var(--border-radius-1);
	background: var(--white);
	box-shadow: var(--modal-box-shadow);

	& > header {
		width: 100%;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--input-border-hover);

		& > button {
			cursor: pointer;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color 300ms;
			border-radius: var(--border-radius-1);
			background: none;

			&:hover {
				background: #dedede;
			}

			& > svg {
				color: var(--body-1);
				font-size: 18px;
			}
		}

		& > h3 {
			margin: 0;
			font-size: 18px;
			color: var(--heading-1);
		}
	}

	& > div.body {
		padding: 16px;
		max-height: 85vh;
		overflow-y: auto;

		& > p {
			color: var(--body-2);
			white-space: pre-line;
		}

		& > div {
			margin: 0;
		}

		& ul {
			border: none !important;
		}
	}

	& > div.buttons {
		border-top: 1px solid var(--input-border);
		grid-column: span 4;
		padding: 6px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& > button ~ button {
			margin-left: 8px;
		}
	}
`;

export const DailyDataFallback = styled.div`
	display: flex;
	align-items: center;

	& > span {
		font-size: 15px;
		color: var(--primary) !important;
		margin-left: 12px;
	}
`;

export const StoredOffs = styled.div`
	background: var(--success);
	padding: 4px 8px;
	color: var(--white) !important;
	border-radius: var(--border-radius-1);
	font-weight: 600;
	font-size: 14px;
`;

export const DrawerContent = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 24px;

	& > header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;

		& > h2 {
			margin: 0;
		}

		& > button {
			width: 38px;
			height: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: none;
			border: none;
			transition: 150ms;
			border-radius: 100%;
			cursor: pointer;

			& > svg {
				font-size: 22px;
				color: var(--body-1);
			}

			&:hover {
				background: #f1f1f1;
			}
		}

		& > button.disabled {
			opacity: 0.6;
			pointer-events: none;
		}
	}

	& > p {
		font-size: 15px;
		color: var(--body-1);
		margin-bottom: 24px;
	}

	& div.dates-interval {
		grid-column: span 4;
		padding: 4px 12px;
		display: flex;
		align-items: center;
		justify-content: center;

		& > strong {
			color: var(--body-1);
		}

		& > span {
			margin: 0 16px;
			color: var(--body-2);
		}
	}
`;

export const GeneratedPlaylist = styled.div`
	width: 100%;
	margin-top: 32px;
	display: flex;
	flex-direction: column;

	& > div.title {
		position: relative;
		margin-bottom: 24px;

		& > div.line {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-bottom: 2px dashed var(--body-2);
			opacity: 0.3;
			pointer-events: none;
			height: 1px;
			width: 100%;
			z-index: 5;
		}

		& > span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 2px 12px;
			background: var(--white);
			color: var(--body-2);
			z-index: 10;
		}
	}

	& ul {
		width: 100%;

		& > li {
			width: 100%;
			height: 38px;
			border-radius: var(--border-radius-1);
			border: 1px solid var(--primary);
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 500;
			color: var(--primary);
		}

		& > li ~ li {
			margin-top: 4px;
		}
	}
`;

export const GenPlaylistItem = styled.li`
	width: 100%;
	height: 38px;
	border-radius: var(--border-radius-1);
	border: 1px solid var(--primary);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primary);
	cursor: pointer;
	background: var(--primary-opacity);
	transition: 300ms;

	& > a {
		width: 100%;
		height: 100%;
		border-radius: var(--border-radius-1);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& svg {
		font-size: 24px;
		margin-left: 16px;
	}

	& ~ li {
		margin-top: 4px;
	}

	&:hover {
		background: var(--primary);
		color: var(--white);
	}

	${({ unactive, alreadyExists }) =>
		(unactive || alreadyExists) &&
		css`
			background: none !important;
			pointer-events: none !important;
			opacity: 0.7 !important;
			color: var(--body-2) !important;
			border-color: var(--body-2) !important;
		`}
`;
