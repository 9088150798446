import React, { useState, useContext, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactAvatar from 'react-avatar';

import { AuthContext } from '../../store/Auth';

// import NotificationChevron from '../NotificationChevron';
import MobileMenu from './MobileMenu';
import {
	Container,
	Wrapper,
	Menu,
	Link as LinkContent,
	Avatar,
	Exit,
	ToggleButton,
	Overlay,
	Footer,
	Copyright,
	Version,
} from './styles';

import {
	BiHome,
	BiMicrophone,
	BiUser,
	BiBullseye,
	BiMenu,
	BiLogOut,
	BiDetail,
} from 'react-icons/bi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';
// import { BsChatDots } from 'react-icons/bs';

const Navigation = () => {
	const { pathname } = useLocation();
	const { user, signOut } = useContext(AuthContext);

	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const handleOpenMenu = useCallback(() => setShowMobileMenu(true), []);
	const handleCloseMenu = useCallback(() => setShowMobileMenu(false), []);

	return (
		<Container>
			<Wrapper>
				<ToggleButton onClick={handleOpenMenu} className='open-mobile-menu'>
					<BiMenu />
				</ToggleButton>
				<Avatar>
					<div className='avatar-wrapper'>
						<ReactAvatar
							round
							className='__avatar'
							src={resolveFileSrc({ fileName: user.profilePic, folder: ['profile_pics'] })}
							name={`${user.name} ${user.surname}`}
							maxInitials={2}
							size='100%'
						/>
					</div>
					<div className='infos'>
						<p>{`${user.name} ${user.surname}`}</p>
						<span>Locutor(a)</span>
					</div>
				</Avatar>
				<Menu>
					<Link to='/home'>
						<LinkContent active={pathname.includes('home')}>
							<BiHome /> <span>Início</span>
						</LinkContent>
					</Link>
					<Link to='/profile'>
						<LinkContent active={pathname.includes('profile')}>
							<BiUser /> <span>Perfil</span>
						</LinkContent>
					</Link>
					<Link to='/send-offs'>
						<LinkContent active={pathname.includes('send-offs')}>
							<BiMicrophone /> <span>Enviar Offs</span>
						</LinkContent>
					</Link>
					<Link to='/opportunities'>
						<LinkContent active={pathname.includes('opportunities')}>
							<BiBullseye /> <span>Oportunidades</span>
						</LinkContent>
					</Link>
					<Link to='/contracts'>
						<LinkContent active={pathname.includes('contracts')}>
							<BiDetail /> <span>Meus Contratos</span>
						</LinkContent>
					</Link>

					<Exit onClick={signOut}>
						<BiLogOut /> <span>Sair</span>
					</Exit>
				</Menu>
			</Wrapper>

			<MobileMenu visible={showMobileMenu} closeMenu={handleCloseMenu} />

			<Overlay visible={showMobileMenu} />

			<Footer>
				<Version className='version'>
					<span>v.</span>
					{process.env.REACT_APP_VERSION}
				</Version>
				<Copyright>&copy; {new Date().getFullYear()} Talk Radio</Copyright>
			</Footer>
		</Container>
	);
};

export default Navigation;
