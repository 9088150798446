import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Recdal from 'recdal';

import { GET, POST, PATCH, DELETE } from '../../services/api';
import { diff } from '../../utils/helpers/dates';

import Fallback from '../../components/Fallback';
import Questions from '../../components/Questions';
import Breadcumbs from '../../components/Breadcumbs';
import DecoratedText from '../../components/DecoratedText';
import Button, { IconButton } from '../../components/Button';
import { Heading1, Heading3 } from '../../components/Typography';
import { Form, TextArea, CurrencyInput } from '../../components/Form';
import {
	Container,
	DetailsWrapper,
	OpportunityDetails as Opportunity,
	Prices,
	GenreContainer,
	Description,
	RadioDetails,
	RadioInfos,
	Schedule,
	Week,
	Day,
	DayHelper,
	SubContainer,
	Proposal,
	ModalContent,
	CalculatedValue,
} from './styles';

import { FiEdit, FiTrash } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

import { AuthContext } from '../../store/Auth';

import { toCurrencyFormat } from '../../utils/helpers/strings';
import { toPTBRLocaleDay } from '../../utils/helpers/others';
import { notify } from '../../components/Notification';

const INITIAL_STACK = [
	{ label: 'Início', pathname: '/' },
	{ label: 'Oportunidades', pathname: '/opportunities' },
];

const TALK_COMMISSION = 0.1;

const OpportunityDetails = () => {
	const { user } = useContext(AuthContext);
	const { opportunity_id } = useParams();

	const questionFormRef = useRef(null);
	const proposalFormRef = useRef(null);
	const removeProposalModalRef = useRef(null);
	const submitEmptyProposalModalRef = useRef(null);
	const editProposalModalRef = useRef(null);
	const editProposalFormRef = useRef(null);
	const commissiontModalRef = useRef(null);

	const [proposalWarns, setProposalWarns] = useState({ description: false, value: false });
	const [actionFallback, setActionFallback] = useState(null);
	const [loading, setLoading] = useState(true);
	const [opportunity, setOpportunity] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [breadcumbsStack, setBreadcumbsStack] = useState([]);
	const [proposal, setProposal] = useState(null);
	const [comment, setComment] = useState('');
	const [calculatedValue, setCalculatedValue] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [opportunity, comments, proposal] = await Promise.all([
					GET(`/api/v2/opportunities/${opportunity_id}`),
					GET(`/api/v2/comments/${opportunity_id}`),
					GET(`/api/v2/proposals/${opportunity_id}?caster=${user._id}`),
				]);

				const { weeklySchedule: objWeeklySchedule, ...rest } = opportunity.data.opportunity;
				const weeklySchedule = [];

				for (const day in objWeeklySchedule) {
					if (objWeeklySchedule.hasOwnProperty(day)) {
						weeklySchedule.push({ day, onAir: objWeeklySchedule[day] });
					}
				}

				setQuestions(comments.data.comments);
				setOpportunity({ ...rest, weeklySchedule });
				setProposal(proposal?.data.proposal);
				setBreadcumbsStack([
					...INITIAL_STACK,
					{ label: opportunity.data.opportunity?.title, pathname: null },
				]);
				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		};

		if (opportunity_id && user) {
			fetchData();
		}
	}, [opportunity_id, user]);

	const handleSubmitQuestion = useCallback(
		async ({ question }) => {
			try {
				const validationSchema = Yup.string().required(
					'Essa pergunta é muito difícil de ser respondida :P'
				);

				await validationSchema.validate(question);

				setActionFallback('c-comment');
				questionFormRef.current.setErrors({});

				const comment = { content: question };
				const { data } = await POST(`/api/v2/comments/question/${opportunity_id}`, comment);

				let { newComment } = data;
				const { _id, content, createdAt, user, userModel } = newComment;

				newComment = {
					_id,
					content,
					createdAt,
					answers: [],
					user: { ...user, userModel },
				};

				setQuestions((questions) => [{ ...newComment }, ...questions]);
				setActionFallback(null);
				setComment('');
				questionFormRef.current.reset();
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					return questionFormRef.current.setFieldError('question', error.message);
				}
			}
		},
		[opportunity_id]
	);

	const handleSubmitProposal = useCallback(async () => {
		try {
			if (proposal) {
				return;
			}

			setActionFallback('c-proposal');

			let payload = proposalFormRef.current.getData();
			payload = {
				...payload,
				value: payload.value ? payload.value * 100 : opportunity.prices.min,
			};

			const { data } = await POST(`/api/v2/proposals/${opportunity_id}`, payload);

			setActionFallback(null);
			submitEmptyProposalModalRef.current.close();
			setProposal(data.proposal);
			return notify('success', 'Sua proposta foi enviada com sucesso');
		} catch (error) {
			console.error(error);
			notify('error', 'Houve um erro ao enviar sua proposta, tente novamente');
		}
	}, [opportunity_id, proposal, opportunity]);

	const handleProposalValueChange = useCallback((value) => {
		const normalizedValue = Number(
			value.replace('R$ ', '').replace(/\./g, '').replace(/,/g, '.')
		);

		const finalValue = normalizedValue - normalizedValue * TALK_COMMISSION;

		setCalculatedValue(finalValue);
	}, []);

	const handleVerifyProposal = useCallback(
		async (formData) => {
			try {
				if (!formData.description || !formData.value) {
					setProposalWarns({
						description: !!formData.description,
						value: !!formData.value,
					});

					return submitEmptyProposalModalRef.current.open();
				}

				setProposalWarns({ description: false, value: false });
				return handleSubmitProposal(formData);
			} catch (error) {
				console.error(error);
			}
		},
		[handleSubmitProposal]
	);

	const handleRemoveProposal = useCallback(async () => {
		try {
			if (!proposal) {
				return;
			}

			setActionFallback('r-proposal');
			removeProposalModalRef.current.lock();

			await DELETE(`/api/v2/proposals/${proposal?._id}`);

			notify('success', 'Sua proposta foi removida com sucesso');
			setProposal(null);
		} catch (error) {
			console.error(error);
			notify('error', 'Houve um erro ao tentar desfazer sua proposta, tente novamente');
		} finally {
			removeProposalModalRef.current.unlock();
			removeProposalModalRef.current.close();
			setActionFallback(null);
		}
	}, [proposal]);

	const handleEditProposal = useCallback(
		async (formData) => {
			try {
				if (!proposal) {
					return;
				}

				const { description, value } = formData;
				const payload = { description, value: value * 100 };

				editProposalModalRef.current.lock();
				setActionFallback('u-proposal');

				const { data } = await PATCH(`/api/v2/proposals/${proposal?._id}`, payload);

				setProposal(data.proposal);
				editProposalModalRef.current.unlock();
				editProposalModalRef.current.close();
				setActionFallback(null);
				notify('success', 'Sua proposta foi atualizada');
			} catch (error) {
				console.error(error);
				notify('error', 'Houve um erro ao editar sua proposta, tente novamente');
			}
		},
		[proposal]
	);

	const handlePickEmoji = useCallback((emoji) => setComment(comment.concat(emoji)), [comment]);

	if (loading) {
		return <Fallback />;
	}

	return (
		<>
			<Container>
				<Breadcumbs stack={breadcumbsStack} />
				<Heading1>Detalhes da Oportunidade</Heading1>

				<DetailsWrapper>
					<Opportunity>
						<Heading3>{opportunity?.title}</Heading3>

						<Prices>
							<strong>{toCurrencyFormat(opportunity?.prices?.min / 100)}</strong>
							<span>~</span>
							<strong>{toCurrencyFormat(opportunity?.prices?.max / 100)}</strong>
						</Prices>

						<GenreContainer>
							<span>{opportunity?.program?.genreId?.name}</span>
						</GenreContainer>

						<Description>{opportunity?.description}</Description>
					</Opportunity>
					<RadioDetails>
						<div className='title'>
							<Heading3>{opportunity?.user?.radioName}</Heading3>
							<p>
								{opportunity?.user?.city} - {opportunity?.user?.state}
							</p>
						</div>

						<RadioInfos>
							<li>
								<div>
									<strong>Publicado</strong>
									<p>{diff(opportunity.createdAt)}</p>
								</div>
							</li>
							<li>
								<div>
									<strong>Candidatos</strong>
									<p>{opportunity.candidates.length} interessados</p>
								</div>
							</li>
						</RadioInfos>

						<Schedule>
							<strong>Cronograma Semanal</strong>

							<Week>
								{opportunity?.weeklySchedule?.map(({ day, onAir }) => (
									<Day key={day} active={onAir}>
										{toPTBRLocaleDay(day)}
									</Day>
								))}
							</Week>

							<DayHelper>
								* Dias com a cor <span></span> são os dias em que o programa vai ao
								ar.
							</DayHelper>
						</Schedule>
					</RadioDetails>
				</DetailsWrapper>

				<SubContainer>
					{proposal ? (
						<Proposal>
							<header>
								<Heading3>Sua Proposta</Heading3>

								<div className='actions'>
									<IconButton
										title='Editar proposta'
										icon={FiEdit}
										onClick={() => editProposalModalRef.current.open()}
										variant='outlined'
									/>
									<IconButton
										onClick={() => removeProposalModalRef.current.open()}
										title='Desfazer proposta'
										color='danger'
										icon={FiTrash}
										variant='outlined'
									/>
								</div>
							</header>

							<span>
								Valor enviado na sua proposta:{' '}
								<strong>{toCurrencyFormat(proposal?.value / 100)}</strong>
							</span>

							{proposal?.description ? (
								<DecoratedText
									text={proposal?.description}
									className='description'
								/>
							) : (
								<span style={{ fontStyle: 'italic', opacity: 0.8 }}>
									Descrição não informada
								</span>
							)}
						</Proposal>
					) : (
						<>
							<Heading3>Fazer uma proposta</Heading3>

							<Form
								ref={proposalFormRef}
								onSubmit={handleVerifyProposal}
								columns='5fr 3fr'>
								<TextArea
									size={1}
									rows={8}
									maxLength={560}
									name='description'
									placeholder='Use essa área para falar sobre você, suas experiências ou qualquer outra coisa relacionada à oportunidade...'
								/>
								<div className='budget'>
									<div>
										<Heading3>Orçamento</Heading3>
										<p>
											O valor informado pelo contratante nessa proposta é
											apenas o que ele julga o valor justo. Sinta-se livre
											para enviar outro valor na sua proposta.
										</p>
									</div>
									<div>
										{calculatedValue !== 0 && (
											<CalculatedValue>
												<p>
													<span>Vocês irá receber</span>
													<strong>
														{toCurrencyFormat(calculatedValue)}
													</strong>
												</p>
												<button
													type='button'
													onClick={() =>
														commissiontModalRef.current.open()
													}>
													Saiba porquê
												</button>
											</CalculatedValue>
										)}
										<CurrencyInput
											name='value'
											onInputChange={handleProposalValueChange}
											style={{ transform: 'translateY(-8.5px)' }}
										/>
									</div>
								</div>
								<div className='buttons-container'>
									<Button
										color='success'
										loading={actionFallback === 'c-proposal'}>
										Enviar Proposta
									</Button>
								</div>
							</Form>
						</>
					)}
				</SubContainer>

				<SubContainer>
					<Heading3>Perguntas e Respostas</Heading3>

					<Form ref={questionFormRef} onSubmit={handleSubmitQuestion} columns='8fr 2fr'>
						<TextArea
							size={1}
							rows={3}
							name='question'
							showEmojis
							onEmojiClick={handlePickEmoji}
							onChange={(e) => setComment(e.target.value)}
							value={comment}
							placeholder=' Sinta-se livre para fazer uma pergunta ao contratante.
							Qualquer comentário feito nessa área será público e visível para todos.'
						/>
						<Button loading={actionFallback === 'c-comment'}>Enviar Pergunta</Button>
					</Form>

					<Questions data={questions} />
				</SubContainer>
			</Container>

			<Recdal ref={removeProposalModalRef}>
				<ModalContent>
					<header>
						<h3>Atenção!</h3>

						<button onClick={() => removeProposalModalRef.current.close()}>
							<MdClose />
						</button>
					</header>
					<div className='message'>
						<p>
							Ao remover sua proposta dessa oportunidade, o contratante não poderá
							mais vê-la e consequentemente aceita-la, deseja continuar mesmo assim?
						</p>
					</div>
					<div className='buttons'>
						<Button
							color='danger'
							loading={actionFallback === 'r-proposal'}
							onClick={handleRemoveProposal}>
							Excluir Proposta
						</Button>
					</div>
				</ModalContent>
			</Recdal>

			<Recdal ref={editProposalModalRef}>
				<ModalContent>
					<header>
						<h3>Editar proposta</h3>

						<button onClick={() => editProposalModalRef.current.close()}>
							<MdClose />
						</button>
					</header>

					<Form
						ref={editProposalFormRef}
						initialData={{
							description: proposal?.description,
							value: proposal?.value / 100,
						}}
						onSubmit={handleEditProposal}>
						<TextArea
							size={4}
							rows={6}
							label='Descrição'
							name='description'
							placeholder='Use essa área para falar sobre você, suas experiências ou qualquer outra coisa relacionada à oportunidade...'
						/>
						<CurrencyInput size={4} name='value' label='Valor' />
					</Form>
					<div className='buttons'>
						<Button
							variant='outlined'
							onClick={() => editProposalModalRef.current.close()}
							disabled={actionFallback === 'u-proposal'}>
							Cancelar
						</Button>
						<Button
							loading={actionFallback === 'u-proposal'}
							onClick={() => editProposalFormRef.current.submitForm()}>
							Atualizar Proposta
						</Button>
					</div>
				</ModalContent>
			</Recdal>

			<Recdal ref={submitEmptyProposalModalRef} onClose={() => setActionFallback(null)}>
				<ModalContent>
					<header>
						<h3>Verifique os seguintes pontos</h3>

						<button onClick={() => submitEmptyProposalModalRef.current.close()}>
							<MdClose />
						</button>
					</header>
					<div className='message'>
						<ul>
							{!proposalWarns.description && (
								<li>
									Você está enviando uma proposta, porém sem nenhum texto ou
									informação incluso. Isso pode diminuir suas chances de conseguir
									um contrato.
								</li>
							)}

							{!proposalWarns.value && (
								<li>
									Você está enviando uma proposta sem informar o valor que
									pretende cobrar. Nesse caso o valor considerado em um possível
									contrato será a margem inferior (
									<strong>
										{toCurrencyFormat(opportunity.prices.min / 100)}
									</strong>
									) estipulada pelo contratante.
								</li>
							)}
						</ul>

						<p>Deseja continuar mesmo assim?</p>
					</div>
					<div className='buttons'>
						<Button
							variant='outlined'
							disabled={actionFallback}
							onClick={() => submitEmptyProposalModalRef.current.close()}>
							Cancelar
						</Button>
						<Button
							loading={actionFallback === 'c-proposal'}
							onClick={handleSubmitProposal}>
							Continuar
						</Button>
					</div>
				</ModalContent>
			</Recdal>

			<Recdal ref={commissiontModalRef}>
				<ModalContent>
					<div className='message'>
						<p>
							Nós da Talk Radio cobramos uma taxa de{' '}
							<strong>{TALK_COMMISSION * 100}%</strong> sobre valor firmado nos
							contratos entre nossos clientes e locutores.
							<br />
							<br />
							Você irá receber essa quantia mensalmente no caso do contrato ser feito
							com o valor que está enviando em sua proposta.
						</p>
					</div>
					<div className='buttons'>
						<Button onClick={() => commissiontModalRef.current.close()}>Entendi</Button>
					</div>
				</ModalContent>
			</Recdal>
		</>
	);
};

export default OpportunityDetails;
