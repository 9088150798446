import './collapse.css';

import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { Collapse } from 'react-collapse';

import { InputContainer, Error, collapseTheme } from './styles';

const Select = ({ name, label, size, ...rest }) => {
	const selectRef = useRef(null);
	const { fieldName, defaultValue, registerField, error } = useField(name);

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: selectRef.current,
			path: 'props.value',
			getValue: (ref) => {
				if (rest.isMulti) {
					if (!ref.props.value) {
						return [];
					}

					return ref.props.value.map((option) => option.value);
				} else {
					if (!ref.props.value) {
						return '';
					}

					return ref.props.value.value;
				}
			},
		});
	}, [fieldName, registerField, rest.isMulti]);

	return (
		<InputContainer {...rest} size={size} error={error} className='__input __select'>
			{label && (
				<label onClick={() => console.log({ ...selectRef.current })} htmlFor={name}>
					{label}
				</label>
			)}
			<ReactSelect
				{...rest}
				ref={selectRef}
				classNamePrefix='react-select'
				placeholder={defaultValue || 'Selecione'}
				defaultValue={rest.options.find(({ value }) => value === defaultValue)}
			/>
			<Collapse isOpened={!!error} theme={collapseTheme}>
				<Error>{error}</Error>
			</Collapse>
		</InputContainer>
	);
};

export default Select;
