import React, { useState } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import { emojis } from './emojis';
import { Container, Emoji, ToggleButton } from './styles';
import { MdTagFaces } from 'react-icons/md';

const Emojis = ({ onEmojiClick }) => {
	const [hideEmojisList, setHideEmojisList] = useState(true);

	const handleEmojiClick = (emoji) => onEmojiClick(emoji);

	return (
		<OutsideClickHandler onOutsideClick={() => setHideEmojisList(true)}>
			<ToggleButton type='button' onClick={() => setHideEmojisList(!hideEmojisList)}>
				<MdTagFaces />
				{!hideEmojisList && (
					<Container>
						{emojis.map((emoji, index) => (
							<Emoji key={index} onClick={() => handleEmojiClick(emoji)}>
								{emoji}
							</Emoji>
						))}
					</Container>
				)}
			</ToggleButton>
		</OutsideClickHandler>
	);
};

export default Emojis;
