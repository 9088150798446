import React, { useState, useEffect, useRef, useCallback } from 'react';
import Recdal from 'recdal';
import { Collapse } from 'react-collapse';

import Billets from './Billets';
import Tabs from '../Tabs';
import Button from '../Button';
import Script from '../Script';
import { Heading3 } from '../Typography';
import { notify } from '../Notification';
import {
	ListItem as Container,
	Details,
	Schedule,
	Week,
	Day,
	Status,
	Value,
	ContractInfos,
	ModalContent,
	TabContent,
	collapseTheme,
	TabHeader,
	collapseStyles,
} from './styles';

import { FiChevronUp, FiRadio } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

import { GET, PATCH } from '../../services/api';

import { toCurrencyFormat } from '../../utils/helpers/strings';
import { toPTBRLocaleDay } from '../../utils/helpers/others';
import { format, parseISO } from 'date-fns';

const Item = ({ data, defaultOpen }) => {
	const scriptModalRef = useRef(null);
	const acceptContractModalRef = useRef(null);
	const restoreContractModalRef = useRef(null);

	const [contract, setContract] = useState(null);
	const [collapsed, setCollapsed] = useState(defaultOpen);
	const [actionFallback, setActionFallback] = useState(null);
	const [billets, setBillets] = useState([]);

	const handleToggleCollapse = useCallback(() => setCollapsed(!collapsed), [collapsed]);

	const initState = useCallback((data) => {
		const { weeklySchedule: objWeeklySchedule, ...rest } = data;
		const weeklySchedule = [];

		for (const day in objWeeklySchedule) {
			if (objWeeklySchedule.hasOwnProperty(day)) {
				weeklySchedule.push({ day, onAir: objWeeklySchedule[day] });
			}
		}

		setContract({ ...rest, weeklySchedule });
	}, []);

	const renderStatusText = useCallback((status) => {
		switch (status) {
			case 1:
				return 'Vigente';
			case 2:
				return 'Encerrado';
			case 3:
				return 'Aguardando renovação';
			default:
				return 'Pendente';
		}
	}, []);

	const renderSignatureDate = useCallback((signatureDate) => {
		if (signatureDate) {
			return `Assinado em ${new Date(signatureDate).toLocaleDateString()}`;
		}

		return <span style={{ opacity: 0.6 }}>Aguardando a aprovação</span>;
	}, []);

	const handleAcceptContract = useCallback(async () => {
		try {
			acceptContractModalRef.current.lock();
			setActionFallback('c-contract');

			const headers = { action: 'accept-contract' };
			const payload = { status: 1 };
			const { data } = await PATCH(`/api/v2/contracts/${contract._id}`, payload, { headers });

			setContract({
				...contract,
				status: 1,
				signatureDate: data.contract.signatureDate,
				endingDate: data.contract.endingDate,
			});
			notify('success', 'Contrato aceito e já vigente');
			setActionFallback(null);

			acceptContractModalRef.current.unlock();
			return acceptContractModalRef.current.close();
		} catch (error) {
			console.error(error);
			notify('error', 'Houve um erro, por favor tente novamente');
		}
	}, [contract]);

	const handleRestoreContract = useCallback(async () => {
		try {
			restoreContractModalRef.current.lock();
			setActionFallback('r-contract');

			const headers = { action: 'restore-contract' };
			const payload = { status: 1 };
			const { data } = await PATCH(`/api/v2/contracts/${contract._id}`, payload, { headers });

			setContract({
				...contract,
				status: 1,
				signatureDate: data.contract.signatureDate,
				endingDate: data.contract.endingDate,
			});
			setActionFallback(null);
			notify('success', 'Contrato renovado e já vigente');

			restoreContractModalRef.current.unlock();
			return restoreContractModalRef.current.close();
		} catch (error) {
			console.error(error);
			setActionFallback(null);

			notify('error', 'Houve um erro, por favor tente novamente');
		}
	}, [contract]);

	const handleFetchBillets = useCallback(async () => {
		try {
			if (!billets.length) {
				setActionFallback('fetching-billets');

				const { data: response } = await GET(`/api/v2/billets/${data._id}`);

				setActionFallback(null);
				setBillets(response.billets);
			}
		} catch (error) {
			console.error(error);
			notify('error', 'Houve um erro buscar os boletos');
		}
	}, [billets, data]);

	useEffect(() => {
		setCollapsed(defaultOpen);
	}, [defaultOpen]);

	useEffect(() => {
		initState(data);
	}, [initState, data]);

	if (!contract) {
		return null;
	}

	return (
		<>
			<Container collapsed={collapsed}>
				<header onClick={handleToggleCollapse}>
					<div className='left'>
						<Heading3>{contract?.program?.name}</Heading3>
						<div className='radio'>
							<FiRadio />
							<p>{contract?.hirer?.radioName}</p>
						</div>
					</div>

					<div className='right'>
						<p className='date'>{renderSignatureDate(contract?.signatureDate)}</p>

						<Status status={contract?.status}>
							{renderStatusText(contract?.status)}
						</Status>

						<Schedule>
							<Week>
								{contract?.weeklySchedule?.map(({ day, onAir }) => (
									<Day key={day} active={onAir}>
										{toPTBRLocaleDay(day)}
									</Day>
								))}
							</Week>
						</Schedule>

						<FiChevronUp />
					</div>
				</header>

				<Collapse isOpened={!collapsed} style={collapseStyles} theme={collapseTheme}>
					<Details>
						<Tabs
							tabsList={[
								{ label: 'Detalhes' },
								{ label: 'Boletos', onClick: handleFetchBillets },
							]}
							content={[
								<TabContent>
									{contract?.status === 0 && (
										<TabHeader>
											<span>Contrato aguardando sua aprovação</span>
											<Button
												onClick={() =>
													acceptContractModalRef.current.open()
												}
												color='success'>
												Assinar Contrato
											</Button>
										</TabHeader>
									)}

									{contract?.status === 3 && (
										<TabHeader>
											<span>Em processo de renovação</span>
											<Button
												onClick={() =>
													restoreContractModalRef.current.open()
												}
												color='success'>
												Renovar Contrato
											</Button>
										</TabHeader>
									)}

									<ContractInfos>
										<li>
											<strong>Contratante</strong>
											<span>
												{contract?.hirer?.name} {contract?.hirer?.surname}
											</span>
										</li>
										<li>
											<strong>Data de Assinatura</strong>
											<span>
												{contract?.signatureDate
													? format(
															parseISO(contract?.signatureDate),
															'dd/MM/yyyy'
													  )
													: '--'}
											</span>
										</li>
										<li>
											<strong>Data de expiração</strong>
											<span>
												{contract?.endingDate
													? format(
															parseISO(contract?.endingDate),
															'dd/MM/yyyy'
													  )
													: '--'}
											</span>
										</li>
										<li>
											<strong>Duração</strong>
											<span>
												{contract?.duration
													? `${contract?.duration} meses`
													: '--'}
											</span>
										</li>
										<li>
											<strong>Descrição do programa</strong>
											<span>{contract?.program?.description}</span>
										</li>
										<li>
											<strong>Modelo</strong>
											<span>
												<button
													onClick={() => scriptModalRef.current.open()}>
													Visualizar {contract?.script?.name}
												</button>
											</span>
										</li>
										<li>
											<strong>Valor</strong>
											{contract?.value ? (
												<Value>
													{toCurrencyFormat(contract?.value / 100)}
													<span> / mês</span>
												</Value>
											) : (
												<span>--</span>
											)}
										</li>
									</ContractInfos>
								</TabContent>,

								<Billets
									data={billets}
									contract={contract}
									fetchingBillets={actionFallback === 'fetching-billets'}
								/>,
							]}
						/>
					</Details>
				</Collapse>
			</Container>

			<Recdal ref={scriptModalRef}>
				<ModalContent>
					<header>
						<h3>{contract?.script?.name}</h3>

						<button onClick={() => scriptModalRef.current.close()}>
							<MdClose />
						</button>
					</header>
					<div className='body'>
						<Script defaultOpen={true} script={contract?.script} />
					</div>
				</ModalContent>
			</Recdal>

			<Recdal ref={acceptContractModalRef}>
				<ModalContent>
					<header>
						<h3>Atenção</h3>
					</header>
					<div className='body'>
						<p>
							Ao aceitar o contrato o mesmo entrará imediatamente em vigência,
							portanto certifique-se de estar de acordo com o valor final (
							<strong>{toCurrencyFormat(contract?.value / 100)}</strong>) estabelecido
							pela parte contratante.
							<br /> Lembrando que você irá receber mensalmente{' '}
							<strong>{toCurrencyFormat((contract?.value / 100) * 0.9)}</strong>{' '}
							devido a taxa de 10% cobrada pela Talk Radio.
							<br /> Deseja Continuar?
						</p>
					</div>
					<div className='buttons'>
						<Button
							color='#9d9d9d'
							variant='outlined'
							onClick={() => acceptContractModalRef.current.close()}>
							Cancelar
						</Button>
						<Button
							color='success'
							loading={actionFallback === 'c-contract'}
							onClick={handleAcceptContract}>
							Aceitar Contrato
						</Button>
					</div>
				</ModalContent>
			</Recdal>

			<Recdal ref={restoreContractModalRef}>
				<ModalContent>
					<header>
						<h3>Atenção</h3>
					</header>
					<div className='body'>
						<p>
							Ao aceitar o contrato o mesmo entrará novamente em vigência por mais {contract?.duration} meses,
							portanto certifique-se de estar de acordo com o valor final (
							<strong>{toCurrencyFormat(contract?.value / 100)}</strong>) estabelecido
							pela parte contratante.
							<br /> Lembrando que você irá receber mensalmente{' '}
							<strong>{toCurrencyFormat((contract?.value / 100) * 0.9)}</strong>{' '}
							devido a taxa de 10% cobrada pela Talk Radio.
							<br /> Deseja Continuar?
						</p>
					</div>
					<div className='buttons'>
						<Button
							color='#9d9d9d'
							variant='outlined'
							onClick={() => restoreContractModalRef.current.close()}>
							Cancelar
						</Button>
						<Button
							color='success'
							loading={actionFallback === 'r-contract'}
							onClick={handleRestoreContract}>
							Aceitar Contrato
						</Button>
					</div>
				</ModalContent>
			</Recdal>
		</>
	);
};

export default Item;
