import React, { useMemo, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { format } from 'date-fns';
import Recdal from 'recdal';

import { Heading3 } from '../../components/Typography';
import { Container, DailyContainer, ObservationsModalContent, Fallback } from './styles';

import { AuthContext } from '../../store/Auth';
import { GET } from '../../services/api';

import { MdClose } from 'react-icons/md';

const Home = () => {
	const detailsModalRef = useRef(null);

	const [daily, setDaily] = useState([]);
	const [fallback, setFallback] = useState(true);
	const [dailyDetails, setDailyDetails] = useState({});

	const { user } = useContext(AuthContext);

	useEffect(() => {
		const fetchContracts = async () => {
			try {
				const dateRelease = format(new Date(), 'dd/MM/yyyy');
				const {
					data: { contracts },
				} = await GET(`/api/v2/contracts?caster=${user._id}&status=1`);

				if (!contracts.length) {
					setFallback(false);
					return setDaily(null);
				}

				const daily = [];

				for (let index = 0; index < contracts.length; index++) {
					const contract = contracts[index];
					const programId = contract.program._id;
					const [{ data: observations }, { data: storedOffs }] = await Promise.all([
						GET(`/api/v2/observations?date=${dateRelease}&contract=${contract._id}`),
						GET(`/api/v2/offs?program=${programId}&dateRelease=${dateRelease}`),
					]);

					const offsNeeded = contract.script.body.filter(({ value }) =>
						value.includes('VOZ')
					).length;

					daily.push({
						contract,
						observations: observations.observations,
						storedOffs: storedOffs.offs.length,
						offsNeeded,
					});
				}

				setDaily(daily);
				setFallback(false);
			} catch (error) {
				console.error(error);
			}
		};

		fetchContracts();
	}, [user]);

	const now = useMemo(() => {
		const now = new Date().getHours();

		if (now >= 4 && now <= 11) {
			return 'Bom dia';
		}

		if (now >= 12 && now <= 17) {
			return 'Boa tarde';
		}

		return 'Boa noite';
	}, []);

	const handleSeeObservations = useCallback((observations, hirer) => {
		const hirerName = `${hirer?.name} ${hirer?.surname}`;
		const { content } = observations;

		setDailyDetails({ hirer: hirerName, observations: content });
		detailsModalRef.current.open();
	}, []);

	const renderDaily = useCallback(
		({ offsNeeded, storedOffs, observations, contract }) => {
			return (
				<p>
					Para o seu contrato de gravações do programa{' '}
					<strong>{contract?.program?.name}</strong> com{' '}
					<strong>{contract?.hirer?.name}</strong>{' '}
					<strong>{contract?.hirer?.surname} </strong>
					{observations ? (
						<span>
							foi deixada uma observação, clique{' '}
							<button
								onClick={() => handleSeeObservations(observations, contract.hirer)}>
								clique aqui para ver
							</button>
						</span>
					) : (
						<span> nenhuma observação foi deixada para hoje</span>
					)}
					. Em relação aos offs,{' '}
					{storedOffs === offsNeeded ? (
						<strong className='success'>
							todos os arquivos necessários({offsNeeded}) já foram enviados{' '}
							<span role='img' aria-label=':D'>
								✌
							</span>
						</strong>
					) : (
						<strong>você ainda precisa enviar {offsNeeded - storedOffs} vozes.</strong>
					)}
				</p>
			);
		},
		[handleSeeObservations]
	);

	return (
		<>
			<Container>
				<h1>
					{now} {user.name} {user.surname}
				</h1>

				{fallback ? (
					<Fallback>Buscando resumo do dia...</Fallback>
				) : (
					<DailyContainer>
						<Heading3>
							Vamos ver o que temos para hoje{' '}
							<span role='img' aria-label=':)'>
								😃
							</span>
						</Heading3>

						{daily === null ? (
							<p className='empty'>Você ainda não possui nenhum contrato vigente</p>
						) : (
							<ul>
								{daily.map((data, index) => (
									<li key={index}>{renderDaily(data)}</li>
								))}
							</ul>
						)}
					</DailyContainer>
				)}
			</Container>

			<Recdal ref={detailsModalRef} onClose={() => setDailyDetails({})}>
				<ObservationsModalContent>
					<header>
						<h3>Observações de hoje deixadas por {dailyDetails.hirer}</h3>

						<button onClick={() => detailsModalRef.current.close()}>
							<MdClose />
						</button>
					</header>
					<div className='body'>
						<p>{dailyDetails?.observations}</p>
					</div>
				</ObservationsModalContent>
			</Recdal>
		</>
	);
};

export default Home;
