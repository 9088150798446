import styled, { css } from 'styled-components';

export const Container = styled.li`
	width: 100%;
	background: var(--white);
	border-radius: var(--border-radius-1);
	border: 1px solid var(--card-border);
	box-shadow: 0px 4px 5px 2px rgba(200, 200, 200, 0.1);
	padding: 16px;
	position: relative;

	& h3:hover {
		text-decoration: ${({ isValid }) => (isValid ? 'underline' : 'none')};
	}

	& > div {
		width: 100%;
	}

	& ~ li {
		margin-top: 12px;
	}

	${({ isValid }) =>
		!isValid &&
		css`
			& .desc {
				opacity: 0.5;
			}

			& * {
				opacity: 0.7;
				pointer-events: none !important;
			}
		`}
`;

export const ContainerHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 24px;

	& > div.title {
		display: flex;
		flex-direction: column;

		& h3 {
			margin: 0;
		}

		& > p {
			font-size: 13px;
			color: var(--body-2);
			font-style: italic;
			margin-bottom: 12px;
		}
	}

	@media (max-width: 645px) {
		flex-direction: column-reverse;

		& > a {
			align-self: flex-end !important;
		}
	}

	@media (max-width: 512px) {
		flex-direction: column;

		& > div {
			width: 100%;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;

			& > a > h3 {
				margin: 0;
			}
		}

		& > a {
			margin-top: 16px;
			width: 100%;

			& > button {
				width: 100%;
			}
		}
	}

	@media (max-width: 415px) {
		& > div {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;

			& > a {
				margin-bottom: 8px;
			}
		}
	}
`;

export const Prices = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: max-content;

	& > span {
		font-weight: 700;
		font-size: 20px;
		color: ${({ isValid }) => (isValid ? 'var(--success)' : 'var(--body-2)')};
		margin: 0 8px;
	}

	& > strong {
		background: ${({ isValid }) => (isValid ? 'var(--success)' : 'var(--body-2)')};
		color: ${({ isValid }) => (isValid ? 'var(--white)' : 'var(--body-2)')};
		border-radius: var(--border-radius-1);
		padding: 3px 8px;
		font-size: 12px;
		font-weight: 600;
	}
`;

export const GenreContainer = styled.div`
	margin-bottom: 24px;

	& > span {
		background: var(--grey-1);
		font-weight: 600;
		color: #515b6d;
		font-size: 13px;
		padding: 6px 12px;
		border-radius: var(--border-radius-3);
	}
`;

export const Description = styled.p`
	width: 100%;
	font-size: 15px;
	color: var(--body-1);
`;

export const MinInfos = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 24px;

	& > div.info {
		display: flex;

		& ~ div {
			margin-left: 32px;
		}

		& > strong {
			font-size: 15px;
			color: var(--body-1);
			margin-right: 8px;
		}

		& > p {
			font-size: 15px;
			color: var(--heading-1);
		}
	}

	@media (max-width: 1045px) {
		justify-content: space-between;

		& > div.info {
			flex-direction: column;
		}
	}

	@media (max-width: 745px) {
		flex-direction: column;
		align-items: flex-start;

		& > div.info {
			flex-direction: row;
			margin-left: 0 !important;

			& ~ div {
				margin-top: 12px;
			}
		}
	}
`;

export const RadioInfos = styled.div`
	& > p.radio-name {
		font-size: 16px;
		color: var(--heading-1);
		margin-bottom: 2px;
		font-weight: 600;
	}

	& > p.radio-address {
		font-size: 12.33px;
		color: var(--body-2);
		margin-bottom: 8px;
	}
`;

export const KilometersIndicator = styled.span`
	color: var(--heading-1);
	width: 100%;
	text-align: center;
	font-size: 26px;
	font-weight: 600;
	padding: 8px;
	opacity: 1 !important;
	position: absolute;
	top: 110px;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 20;
`;
