import React, { useState, useEffect, useRef, useContext } from 'react';
import Avatar from 'react-avatar';
import Recdal from 'recdal';
import * as Yup from 'yup';

import { POST, PUT, DELETE } from '../../services/api';
import { diff } from '../../utils/helpers/dates';

import Answers from './Answers';
import Button, { IconButton } from '../Button';
import { Form, TextArea } from '../Form';
import { Question as Container, AnswerButton, ModalContent } from './styles';
import { notify } from '../Notification';
import { FiEdit, FiTrash } from 'react-icons/fi';

import { AuthContext } from '../../store/Auth';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';

const Question = ({ data, removeQuestion }) => {
	const { user } = useContext(AuthContext);

	const formRef = useRef(null);
	const editFormRef = useRef(null);
	const editModalRef = useRef(null);
	const deleteModalRef = useRef(null);

	const [answer, setAnswer] = useState('');
	const [answers, setAnswers] = useState([]);
	const [question, setQuestion] = useState({});
	const [actionFallback, setActionFallback] = useState(null);
	const [postingAnswer, setPostingAnswer] = useState(false);
	const [hideAnswerForm, setHideAnswerForm] = useState(true);

	const handleShowAnswerForm = () => setHideAnswerForm(false);
	const handleHideAnswerForm = () => {
		setAnswer('');
		setHideAnswerForm(true);
	};

	const handleInputChange = (e) => setAnswer(e.target.value);
	const handlePickEmoji = (emoji) => setAnswer(answer.concat(emoji));

	const handleSubmitAnswer = async ({ answer }) => {
		try {
			setPostingAnswer(true);

			const { data } = await POST(`/api/v2/comments/answer/${question._id}`, {
				content: answer,
			});

			let { newAnswer } = data;
			const { _id, user, content, createdAt, userModel } = newAnswer;

			newAnswer = {
				_id,
				content,
				createdAt,
				user: { ...user, userModel },
			};

			setPostingAnswer(false);

			setTimeout(() => {
				setAnswers([...answers, { ...newAnswer }]);
				handleHideAnswerForm();
			}, 200);
		} catch (error) {
			notify('error', 'Ocorreu um erro, tente novamente');
			console.log(error);
		}
	};

	const handleEditQuestion = async (formData) => {
		try {
			const validation = Yup.string().required('Preencha o campo de pergunta');
			await validation.validate(formData.content);

			setActionFallback('e-question');
			editModalRef.current.lock();

			await PUT(`/api/v2/comments/${question._id}`, formData);

			setQuestion({ ...question, content: formData.content });
			notify('success', 'Edição feita com sucesso');
		} catch (error) {
			console.error(error);

			if (error instanceof Yup.ValidationError) {
				return editFormRef.current.setFieldError('content', error.message);
			}

			notify('error', 'Houve um erro ao tentar editar');
		} finally {
			setActionFallback(null);
			editModalRef.current.unlock();
			editModalRef.current.close();
		}
	};

	const handleDeleteQuestion = async () => {
		try {
			setActionFallback('d-question');
			deleteModalRef.current.lock();

			await DELETE(`/api/v2/comments/${question._id}`);

			deleteModalRef.current.unlock();
			deleteModalRef.current.close();

			setTimeout(() => {
				removeQuestion(question._id);
			}, 10);
		} catch (error) {
			console.error(error);

			deleteModalRef.current.unlock();
			setActionFallback(null);
			notify('error', 'Houve um erro, tente novamente');
		}
	};

	useEffect(() => {
		if (data) {
			const { answers, ...rest } = data;

			setAnswers(answers);
			setQuestion(rest);
		}
	}, [data]);

	return (
		<>
			<Container className='question'>
				<Avatar
					round
					src={resolveFileSrc({ folder: ['profile_pics'], fileName: question?.user?.profilePic })}
					name={question.username}
					size={40}
				/>
				<div className='content'>
					<div className='question'>
						<header>
							<div className='title'>
								<strong timestamp={` • ${diff(question.createdAt)}`}>{question.username}</strong>
								<p>{question.content}</p>
							</div>

							{user._id === question?.user?._id && (
								<div className='actions'>
									<IconButton
										title='Editar'
										icon={FiEdit}
										onClick={() => editModalRef.current.open()}
										variant='outlined'
									/>
									<IconButton
										onClick={() => deleteModalRef.current.open()}
										title='Excluir'
										icon={FiTrash}
										variant='outlined'
									/>
								</div>
							)}
						</header>
					</div>

					<Answers data={answers} />

					{hideAnswerForm ? (
						<AnswerButton onClick={handleShowAnswerForm}>Responder</AnswerButton>
					) : (
						<Form ref={formRef} onSubmit={handleSubmitAnswer}>
							<TextArea
								name='answer'
								value={answer}
								size={4}
								showEmojis
								onEmojiClick={handlePickEmoji}
								onChange={handleInputChange}
							/>

							<div className='buttons'>
								<Button
									variant='outlined'
									type='button'
									disabled={postingAnswer}
									onClick={handleHideAnswerForm}>
									Cancelar
								</Button>
								<Button disabled={!answer} loading={postingAnswer}>
									Enviar
								</Button>
							</div>
						</Form>
					)}
				</div>
			</Container>

			<Recdal ref={editModalRef}>
				<ModalContent>
					<header>
						<h3>Editar comentário</h3>
					</header>

					<Form
						ref={editFormRef}
						onSubmit={handleEditQuestion}
						initialData={{ content: question.content }}>
						<TextArea name='content' size={4} />
					</Form>

					<div className='buttons'>
						<Button
							variant='outlined'
							disabled={actionFallback}
							onClick={() => editModalRef.current.close()}>
							Cancelar
						</Button>
						<Button
							loading={actionFallback === 'e-question'}
							onClick={() => editFormRef.current.submitForm()}>
							Editar
						</Button>
					</div>
				</ModalContent>
			</Recdal>

			<Recdal ref={deleteModalRef}>
				<ModalContent>
					<header>
						<h3>Atenção!</h3>
					</header>

					<div className='message'>
						<p>Realmente deseja remover esse comentário?</p>
					</div>

					<div className='buttons'>
						<Button
							variant='outlined'
							disabled={actionFallback}
							onClick={() => deleteModalRef.current.close()}>
							Cancelar
						</Button>
						<Button loading={actionFallback === 'd-question'} onClick={handleDeleteQuestion}>
							Excluir
						</Button>
					</div>
				</ModalContent>
			</Recdal>
		</>
	);
};

export default Question;
