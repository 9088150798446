import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	& > div ~ div {
		margin-top: 24px;
	}
`;

export const DetailsWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 5fr 3fr;
	gap: 0 12px;
	border-radius: var(--border-radius-1);

	& > div {
		border: 1px solid var(--card-border);
		box-shadow: 0px 4px 5px 2px rgba(200, 200, 200, 0.1);
		background: var(--white);
		padding: 16px;
		grid-column: span 1;
	}

	@media (max-width: 768px) {
		grid-template-columns: 100%;
		gap: 12px 0;
	}
`;

export const OpportunityDetails = styled.div`
	border-radius: var(--border-radius-1);

	& > div {
		width: 100%;
	}
`;

export const Prices = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: max-content;
	margin-bottom: 16px;

	& > span {
		font-weight: 700;
		font-size: 20px;
		color: var(--success);
		margin: 0 8px;
	}

	& > strong {
		background: var(--success);
		color: var(--white);
		border-radius: var(--border-radius-1);
		padding: 3px 8px;
		font-size: 12px;
		font-weight: 600;
	}
`;

export const GenreContainer = styled.div`
	margin-bottom: 24px;

	& > span {
		background: var(--grey-1);
		font-weight: 600;
		color: #515b6d;
		font-size: 13px;
		padding: 6px 12px;
		border-radius: var(--border-radius-3);
	}
`;

export const Description = styled.p`
	width: 100%;
	font-size: 15px;
	color: var(--body-1);
`;

export const RadioDetails = styled.div`
	border-radius: var(--border-radius-1);

	& > div.title {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;

		& > h3 {
			font-size: 17px;
			margin-bottom: 4px;
		}

		& > p {
			font-size: 12.33px;
			color: var(--body-2);
		}
	}
`;

export const RadioInfos = styled.ul`
	display: flex;
	border-radius: var(--border-radius-1);

	& > li {
		& ~ li {
			margin-left: 24px;
		}

		& > div {
			display: flex;
			flex-direction: column;
			margin-bottom: 12px;

			& > strong {
				font-size: 14.33px;
				color: var(--body-1);
				margin-right: 8px;
				font-weight: 600;
			}

			& > p {
				font-size: 14.33px;
				color: var(--body-2);
			}
		}
	}
`;

export const Schedule = styled.div`
	margin-top: 8px;
	padding: 8px;
	border-radius: var(--border-radius-1);
	border: 1px solid var(--grey-1);

	& > strong {
		font-size: 14.33px;
		color: var(--body-1);
		margin-right: 8px;
		font-weight: 600;
	}
`;

export const Week = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 0 4px;
	padding: 8px 0;
`;

export const Day = styled.span`
	padding: 4px;
	border-radius: var(--border-radius-1);
	border-width: 1px;
	border-style: solid;
	text-transform: uppercase;
	font-weight: 600;
	grid-column: span 1;
	text-align: center;
	font-size: 13px;

	color: ${({ active }) => (active ? 'var(--white)' : 'var(--grey-1)')};
	border-color: ${({ active }) => (active ? 'var(--primary)' : 'var(--grey-1)')};
	background: ${({ active }) => (active ? 'var(--primary)' : 'none)')};
	opacity: ${({ active }) => (active ? 1 : 0.7)};
	pointer-events: none;
`;

export const DayHelper = styled.p`
	margin-top: 12px;
	font-style: italic;
	color: var(--body-2) !important;
	font-size: 12px !important;
	display: flex;
	align-items: center;

	& > span {
		width: 10px !important;
		height: 10px !important;
		border-radius: 2px !important;
		background-color: var(--primary) !important;
		margin: 0 4px;
	}
`;

export const SubContainer = styled.div`
	border: 1px solid var(--card-border);
	box-shadow: 0px 4px 5px 2px rgba(200, 200, 200, 0.1);
	background: var(--white);
	padding: 16px;
	border-radius: var(--border-radius-1);

	& > h3 {
		margin-bottom: 12px;
	}

	& > form {
		display: grid;
		gap: 12px;

		& > div.budget {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			& > div {
				& > h3 {
					font-size: 16px;
					margin-bottom: 8px;
				}

				& > p {
					font-size: 13.33px;
					color: var(--body-2);
				}

				& > input {
					width: 100%;
				}
			}

			@media (max-width: 768px) {
				& > div > p {
					margin-bottom: 8px;
				}
			}
		}

		& > div.buttons-container {
			display: flex;
			grid-column: span 2;
			text-align: start;

			@media (max-width: 768px) {
				grid-column: span 1;
			}
		}

		@media (max-width: 768px) {
			grid-template-columns: 100%;
			gap: 12px 0;

			& button {
				margin-top: 16px;
				max-width: max-content;
			}
		}
	}
`;

export const Proposal = styled.div`
	display: flex;
	flex-direction: column;

	& > header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		& > div.actions {
			display: flex;

			& > button {
				margin-left: 8px;
			}
		}
	}

	& > span {
		font-size: 16px;
		color: var(--body-2);
		margin-bottom: 16px;

		& > strong {
			padding-left: 2px;
			color: var(--success);
		}
	}

	& > p.description {
		font-size: 16px;
		color: var(--body-1);
		white-space: pre-line;
	}

	& > div:last-child {
		margin: none;
	}
`;

export const ModalContent = styled.div`
	width: 550px;
	max-height: 90vh;
	max-width: 90vw;
	background: var(--white);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	border-radius: var(--border-radius-1);
	overflow-y: auto;

	& > header {
		width: 100%;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--input-border-hover);

		& > button {
			cursor: pointer;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color 300ms;
			border-radius: var(--border-radius-1);
			background: none;

			&:hover {
				background: #dedede;
			}

			& > svg {
				color: var(--body-1);
				font-size: 18px;
			}
		}

		& > h3 {
			margin: 0;
			font-size: 18px;
			color: var(--heading-1);
		}
	}

	& > div.message {
		width: 100%;
		padding: 24px;

		& > ul {
			margin-bottom: 24px;
			padding-left: 24px;

			& > li {
				list-style: circle !important;
				font-size: 16px;
				color: var(--body-2);
			}

			& > li ~ li {
				margin-top: 12px;
			}
		}

		& > p {
			font-size: 16px;
			color: var(--body-2);
		}
	}

	& > form {
		width: 100%;
		padding: 12px;
	}

	& > div.buttons {
		border-top: 1px solid var(--input-border);
		grid-column: span 4;
		padding: 6px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& > button ~ button {
			margin-left: 8px;
		}
	}
`;

export const CalculatedValue = styled.div`
	padding: 8px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	margin-bottom: 16px;

	& > p {
		text-align: end;

		& > span {
			color: var(--body-2);
			margin-right: 8px;
		}

		& > strong {
			font-size: 18px;
			color: var(--body-1);
		}
	}

	& > button {
		font-size: 11px;
		text-transform: uppercase;
		color: var(--primary);
		border: none;
		background: none;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
`;
