import styled from 'styled-components';

import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';

export const Container = styled.div`
	width: 100%;
	padding: 24px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 32px;

	& ul.__pagination {
		display: flex;

		& li {
			user-select: none;
			color: var(--heading-1);
			font-weight: 600;
			cursor: pointer;
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 6px;
			border-radius: var(--border-radius-1);
			transition: background-color 300ms;

			& a {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:hover {
				background: var(--grey-1);
			}
		}

		& li:first-child {
			margin-right: 24px;
			width: fit-content;
		}

		& li:last-child {
			margin-left: 24px;
			width: fit-content;
		}

		& li.disabled {
			opacity: 0.6;
			pointer-events: none;
		}
	}

	& .active {
		background: var(--primary);
		color: var(--white) !important;
		pointer-events: none;
	}
`;

export const ArrowContainer = styled.span`
	display: flex;
	padding: 0 12px;

	& > span {
		font-size: 16px;
		color: var(--heading-1);
	}
`;

export const ArrowLeft = styled(FiArrowLeft)`
	font-size: 20px;
	margin-right: 10px;
`;

export const ArrowRight = styled(FiArrowRight)`
	font-size: 20px;
	margin-left: 10px;
`;
