import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import querystring from 'query-string';

import { GET } from '../../services/api';

import Fallback from '../../components/Fallback';
import Pagination from '../../components/Pagination';
import Opportunity from '../../components/Opportunity';
import Breadcumbs from '../../components/Breadcumbs';
import { Form, Select } from '../../components/Form';
import { Heading1 } from '../../components/Typography';
import { Container, OpportunitiesContainer, NoOportunities } from './styles';

import { BsListNested } from 'react-icons/bs';

const stack = [
	{ label: 'Início', pathname: '/' },
	{ label: 'Oportunidades', pathname: null },
];

const options = {
	status: [
		{ value: 'validsOnly', label: 'Somente conexões válidas' },
		{ value: 'invalidsOnly', label: 'Somente conexões inválidas' },
		{ value: 'all', label: 'Exibir tudo' },
	],
	pageSize: [
		{ value: 5, label: '5 items por página' },
		{ value: 10, label: '10 items por página' },
		{ value: 15, label: '15 items por página' },
		{ value: 30, label: '30 items por página' },
	],
};

const Opportunities = () => {
	const history = useHistory();
	const { search, pathname } = useLocation();

	const [loading, setLoading] = useState(true);
	const [opportunities, setOpportunities] = useState([]);
	const [totalOpportunities, setTotalOpportunities] = useState(0);

	const [searchParams, setSearchParams] = useState(() => {
		const query = querystring.parse(search);

		return {
			currentPage: query?.currentPage || 0,
			pageSize: query?.pageSize || 10,
			status: query?.status || 'validsOnly',
		};
	});

	useEffect(() => {
		const queryParams = [];

		for (const key in searchParams) {
			if (searchParams.hasOwnProperty(key)) {
				queryParams.push(`${key}=${searchParams[key]}`);
			}
		}

		return history.push(`${pathname}?${queryParams.join('&')}`);
	}, [searchParams, history, pathname]);

	useEffect(() => {
		const fetchOpportunities = async () => {
			try {
				setLoading(true);

				const queryParams = [];

				for (const param in searchParams) {
					if (searchParams.hasOwnProperty(param)) {
						queryParams.push(`${param}=${searchParams[param]}`);
					}
				}

				const {
					data: { opportunities, totalOpportunities },
				} = await GET(`/api/v2/opportunities?${queryParams.join('&')}`);

				setOpportunities(opportunities);
				setTotalOpportunities(totalOpportunities);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};

		fetchOpportunities();
	}, [searchParams]);

	const handlePaginateTo = useCallback(
		(targetPage) => {
			return setSearchParams({ ...searchParams, currentPage: targetPage });
		},
		[searchParams]
	);

	const handleSelectChange = useCallback(
		(key, value) => {
			const query = querystring.parse(search);

			if (query[key] === value) {
				return;
			}

			return setSearchParams({ ...searchParams, [key]: value });
		},
		[searchParams, search]
	);

	if (loading) {
		return <Fallback />;
	}

	return (
		<Container>
			<Breadcumbs stack={stack} />

			<div className='header'>
				<Heading1>Oportunidades</Heading1>

				<Form
					initialData={{
						status: searchParams.status,
						pageSize: searchParams.pageSize,
					}}>
					<Select
						name='status'
						onChange={({ value }) => handleSelectChange('status', value)}
						options={options.status}
					/>

					<Select
						name='pageSize'
						onChange={({ value }) => handleSelectChange('pageSize', value)}
						options={options.pageSize}
					/>
				</Form>
			</div>

			{opportunities.length > 0 ? (
				<OpportunitiesContainer>
					{opportunities.map((opportunity) => (
						<Opportunity key={opportunity._id} data={opportunity} />
					))}
				</OpportunitiesContainer>
			) : (
				<NoOportunities>
					<BsListNested />
					<p>Não há oportunidades disponíveis</p>
				</NoOportunities>
			)}
			{opportunities.length > 0 && (
				<Pagination
					paginateTo={handlePaginateTo}
					currentPage={searchParams.currentPage}
					pageCount={Math.ceil(totalOpportunities / searchParams.pageSize)}
					pageSize={searchParams.pageSize}
				/>
			)}
		</Container>
	);
};

export default Opportunities;
