import styled, { css } from 'styled-components';
import { FiChevronRight } from 'react-icons/fi';

export const Container = styled.ul`
	display: flex;
    margin-bottom: 16px;
`;

export const Ancor = styled.li`
	display: flex;
	align-items: center;
	font-size: 15px;
	position: relative;
	color: var(--body-1);
	opacity: 0.7;
	text-transform: capitalize;
	
	&:hover {
		text-decoration: underline;
	}

	${({ isLast }) =>
		isLast &&
		css`
			pointer-events: none;
			opacity: 0.9;
		`}
`;

export const Chevron = styled(FiChevronRight)`
	font-size: 13px;
	margin: 0 4px;
	color: var(--body-1);

	${({ isLast }) =>
		isLast &&
		css`
			margin-right: 0;
			opacity: 0.7;
		`}
`;
