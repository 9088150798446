import styled from 'styled-components';

export const Container = styled.main`
	overflow: hidden;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 30% 70%;
	grid-template-rows: 60px auto;
	grid-template-areas:
		'BG LG'
		'BG LG';

	@media(max-width: 960px) {
		grid-template-columns: 25% 75%;
	}

	@media(max-width: 768px) {
		grid-template-columns: 15% 85%;
	}

	@media(max-width: 512px) {
		grid-template-columns: 100%;
		grid-template-areas:
			'LG'
			'LG';
	}
`;

export const FormContainer = styled.section`
	grid-area: LG;
	display: flex;
	align-items: center;
	padding: 64px;
	display: flex;
	flex-direction: column;

	overflow-y: auto;

	& > h1 {
		margin-bottom: 32px;
		font-size: 28px;
		width: 100%;
		text-align: start;
		color: var(--heading-1);
		font-weight: 600;
	}

	& > p {
		font-size: 15px;
		color: var(--body-1);
		margin: 32px 0;
		width: 100%;
		text-align: center;

		& > a {
			color: var(--primary);
		}

		@media(max-width: 340px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content:  space-between;

			& > a {
				margin-top: 4px;
			}
		}
	}
	
	@media(max-width: 512px) {
		& div.__input {
			grid-column: span 4 !important;
		}
	}

	@media(max-width: 650px) {
		padding: 32px 16px;
	}
	

	@media(max-width: 340px) {
		padding: 32px 12px;
	}
`;

export const Background = styled.div`
	grid-area: BG;
	background-image: url(${({ bg }) => bg});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	@media(max-width: 512px) {
		display: none;
	}
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	z-index: 2;
	background: rgba(10, 10, 10, 0.2);
`;
