import 'react-h5-audio-player/lib/styles.css';

import styled from 'styled-components';
import ReactAudioPlayer from 'react-h5-audio-player';

export const Container = styled.div`
	width: 100%;
`;

export const FormContainer = styled.div`
	width: 100%;
	background: var(--white);
	border-radius: var(--border-radius-1);
	border: 1px solid var(--card-border);
	box-shadow: 0px 4px 5px 2px rgba(200, 200, 200, 0.1);
	padding: 16px;
	display: grid;
	grid-template-columns: 1fr 1.8fr;
	gap: 24px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
	}

    @media (max-width: 512px) {        
		& form {
            grid-template-columns: 1fr;

            & > .__input {
                grid-column: span 2;
            }
        }
	}
`;

export const FilesFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
    justify-content: space-between;
    padding-bottom: 52px;

	@media (max-width: 768px) {
		flex-direction: row;
        padding-bottom: 0;
	}

	@media (max-width: 512px) {        
		flex-direction: column;
	}
`;

export const AvatarWrapper = styled.div`
	margin-bottom: 16px;
	cursor: pointer;
	width: 180px;
	height: 180px;
	position: relative;
	cursor: pointer;
	border-radius: 100%;

	&:hover {
		& > label {
			opacity: 1;
		}
	}

	& > input {
		display: none;
	}

	& > label {
		cursor: pointer;
		z-index: 10;
		opacity: 0.5;
		background: rgba(0, 0, 0, 0.7);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		color: var(--white);
		transition: 100ms;
		width: 100%;
		height: 100%;
		border-radius: 100%;

		& > svg {
			font-size: 26px;
			margin-right: 2px;
		}

		& > p {
			font-weight: 500;
			font-size: 16px;
		}
	}
`;

export const Player = styled(ReactAudioPlayer)`
	background-color: var(--primary);
	border-radius: var(--border-radius-1);
	box-shadow: none;

	& .rhap_controls-section {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.rhap_additional-controls {
		display: none;
	}

	button {
		color: var(--white);
	}

	button.rhap_repeat-button {
		display: none;
	}

	.rhap_progress-indicator,
	.rhap_volume-indicator {
		color: var(--white);
	}

	.rhap_volume-indicator,
	.rhap_volume-bar,
	.rhap_progress-indicator,
	.rhap_progress-bar,
	.rhap_download-progress {
		background: var(--white) !important;
	}

	.rhap_current-time {
		font-weight: 500;
		color: var(--white);
	}

	.rhap_time {
		font-weight: 500;
		color: var(--white);
	}
`;

export const PlayerHeader = styled.div`
	padding: 12px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > svg {
		color: var(--white);
		font-size: 35px;
	}

	& > span {
		font-weight: 500;
		color: var(--white);
		font-size: 25px;
	}
`;

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	margin: 24px 0;
	background: #dedede;

	@media (max-width: 768px) {
		height: 100%;
		width: 1px;
		margin: 0 24px;
	}
`;

export const PlayerFooter = styled.div`
	padding-top: 12px;
	display: flex;

	& > label {
		text-align: center;
		width: 100%;
		background: #e09c00;
		padding: 12px 16px;
		border-radius: 12px;
		font-size: 13px;
		color: var(--white);
		cursor: pointer;
		transition: 300ms;
		text-transform: uppercase;
		font-weight: 500;

		&:hover {
			background: #bb8304;
		}
	}

	& > input {
		display: none;
	}
`;
