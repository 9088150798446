import './collapse.css';

import React, { useState, useEffect } from 'react';

import Question from './Question';
import { Heading3 } from '../Typography';
import { Container, NoQuestions } from './styles';

const Questions = ({ data }) => {
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		if (data.length) {
			setQuestions(data);
		}
	}, [data]);

	const removeQuestion = (questionId) => {
		return setQuestions(questions.filter((question) => question._id !== questionId));
	};

	return (
		<Container>
			<Heading3>Últimas Perguntas</Heading3>

			{questions.length ? (
				questions?.map((question) => {
					const username =
						question.user.userModel === 'casters'
							? `${question.user.name} ${question.user.surname}`
							: question.user.radioName;

					return (
						<Question
							key={question._id}
							removeQuestion={removeQuestion}
							data={{ ...question, username }}
						/>
					);
				})
			) : (
				<NoQuestions>Nenhuma pergunta foi feita ainda</NoQuestions>
			)}
		</Container>
	);
};

export default Questions;
