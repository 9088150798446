import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 32px;

	& > h3 {
		font-size: 16px;
		margin-bottom: 24px;
	}
`;

export const AnswersContainer = styled.div`
	padding-left: 6px;
	display: flex;
	flex-direction: column;
	margin-top: 12px;

	& > button {
		background: none;
		font-size: 14px;
		font-weight: 600;
		padding: 4px;
		color: var(--primary);
		margin-bottom: 8px;
		cursor: pointer;
		max-width: max-content;
		text-align: start;
		border-radius: var(--border-radius-1);
		transition: background-color 300ms;

		&:hover {
			background: #ffc2001c;
		}
	}

	& div.answers-list {
		display: flex;
		flex-direction: column;

		& > p {
			margin: 12px 0;
			font-size: 15px;
			color: var(--body-2);
		}
	}
`;

export const Question = styled.div`
	width: 100%;
	display: flex;

	& ~ div.question {
		margin-top: 32px;
	}

	& > .sb-avatar {
		margin-right: 16px;
	}

	& > div.content {
		width: 100%;

		& > div.question {
			padding: 8px 0;

			& > header {
				display: flex;
				margin-bottom: 4px;
				display: flex;
				justify-content: space-between;

				&:hover {
					& > div.actions {
						pointer-events: all;
						visibility: visible;
					}
				}

				& > div.actions {
					pointer-events: none;
					display: flex;
					visibility: hidden;

					& > button {
						margin-left: 8px;
					}
				}

				& > div.title {
					& > strong {
						font-size: 15px;
						margin-bottom: 4px;
						color: var(--heading-1);
						position: relative;

						&::after {
							position: absolute;
							content: attr(timestamp);
							top: 50%;
							left: calc(100% + 4px);
							transform: translateY(-50%);
							font-size: 14px;
							color: var(--body-2);
							opacity: 0.75;
							pointer-events: none;
							font-weight: 600;
							width: max-content;
						}
					}

					& > p {
						font-size: 15px;
						color: var(--body-2);
						word-break: break-all;
					}
				}
			}
		}
	}

	& form {
		width: 100%;

		& > div.buttons {
			grid-column: span 4;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			& > button ~ button {
				margin-left: 6px;
			}
		}
	}
`;

export const Answer = styled.div`
	padding: 8px 0;
	display: flex;
	justify-content: space-between;

	&:hover {
		& > div.actions {
			display: flex;
			pointer-events: all;
		}
	}

	& > div.actions {
		pointer-events: none;
		display: none;

		& > button {
			margin-left: 8px;
		}
	}

	& > div > strong {
		font-size: 15px;
		margin-bottom: 4px;
		color: var(--heading-1);
		opacity: 0.9;
		position: relative;

		&::after {
			position: absolute;
			content: attr(timestamp);
			top: 50%;
			left: calc(100% + 4px);
			transform: translateY(-50%);
			font-size: 14px;
			color: var(--body-2);
			opacity: 0.75;
			pointer-events: none;
			font-weight: 600;
			width: max-content;
		}
	}

	& > div > p {
		font-size: 15px;
		color: var(--body-2);
	}
`;

export const NoQuestions = styled.div`
	width: 100%;
	padding: 32px 16px;
	font-size: 18px;
	color: var(--body-2);
	opacity: 0.6;
`;

export const AnswerButton = styled.button`
	font-size: 13px;
	text-transform: uppercase;
	padding: 6px 10px;
	border-radius: var(--border-radius-1);
	background: none;
	transition: background-color 300ms;
	cursor: pointer;
	color: var(--body-2);
	font-weight: 600;

	&:hover {
		background: var(--grey-1);
	}
`;


export const ModalContent = styled.div`
	width: 550px;
	max-height: 90vh;
	max-width: 90vw;
	background: var(--white);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	border-radius: var(--border-radius-1);
	overflow-y: auto;

	& > header {
		width: 100%;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--input-border-hover);

		& > h3 {
			margin: 0;
			font-size: 18px;
			color: var(--heading-1);
		}
	}

	& > div.message {
		width: 100%;
		padding: 24px;

		& > ul {
			margin-bottom: 24px;
			padding-left: 24px;

			& > li {
				list-style: circle !important;
				font-size: 16px;
				color: var(--body-2);
			}

			& > li ~ li {
				margin-top: 12px;
			}
		}

		& > p {
			font-size: 16px;
			color: var(--body-2);
		}
	}

	& > form {
		width: 100%;
		padding: 12px;
	}

	& > div.buttons {
		border-top: 1px solid var(--input-border);
		grid-column: span 4;
		padding: 6px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& > button ~ button {
			margin-left: 8px;
		}
	}
`;


export const collapseTheme = {
	collapse: 'ReactCollapse--collapse',
	content: 'ReactCollapse--content',
};
