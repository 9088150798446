import styled from 'styled-components';

export const Container = styled.main`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 40% 60%;
	grid-template-rows: auto auto;
	grid-template-areas:
		'HD BG'
		'LG BG';

	@media (max-width: 960px) {
		grid-template-columns: 60% 40%;
	}

	@media (max-width: 768px) {
		grid-template-columns: 75% 25%;
	}

	@media (max-width: 512px) {
		grid-template-columns: 100%;
		grid-template-areas:
			'HD'
			'LG';
	}
`;

export const FormContainer = styled.section`
	grid-area: LG;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 64px;
	display: flex;
	flex-direction: column;

	& > h1 {
		margin-bottom: 32px;
		font-size: 28px;
		width: 100%;
		text-align: start;
		color: var(--heading-1);
		font-weight: 600;
	}

	& form > div.forgot {
		grid-column: span 4;
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > a {
			cursor: pointer;
			font-size: 15px;
			color: var(--body-1);
			text-align: start;

			&:hover {
				color: var(--primary);
			}
		}
	}

	& > p {
		font-size: 15px;
		color: var(--body-1);
		margin-top: 32px;
		width: 100%;
		text-align: center;

		& > a {
			color: var(--primary);
		}

		@media (max-width: 340px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			& > a {
				margin-top: 4px;
			}
		}
	}

	@media (max-width: 650px) {
		padding: 0 32px;
	}

	@media (max-width: 340px) {
		padding: 0 12px;
	}
`;

export const Header = styled.header`
	grid-area: HD;
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;

	& > p {
        padding: 0 24px;
		font-size: 14px !important;
		width: 100% !important;
		text-align: center !important;
		margin-top: 12px !important;
	}

	& > h1 {
		font-size: 20px;
		opacity: 0.6;
	}
`;

export const Background = styled.div`
	grid-area: BG;
	background-image: url(${({ bg }) => bg});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	@media (max-width: 512px) {
		display: none;
	}
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	z-index: 2;
	background: rgba(10, 10, 10, 0.2);
`;

export const Logo = styled.div`
	& > img {
		width: 50px;
	}
`;
