import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	& > div.header {
		width: 100%;
		margin-bottom: 16px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		& > h1 {
			margin: 0;
		}

		& > form {
			display: flex;
			justify-content: flex-end;

			& > div {
				width: 280px;
			}

			& > div ~ div {
				width: 200px;
				margin-left: 8px;
			}
		}
	}
`;

export const OpportunitiesContainer = styled.ul`
	width: 100%;
	margin-bottom: 32px;
`;

export const NoOportunities = styled.div`
	width: 100%;
	flex: 1;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	& > svg {
		font-size: 180px;
		/* margin-bottom: 24px; */
		color: var(--grey-1);
	}

	& > p {
		color: var(--grey-1);
		font-size: 24px;
		font-weight: 600;
	}
`;
