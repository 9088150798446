import React from 'react';
import ReactPaginate from 'react-paginate';

import { Container, ArrowContainer, ArrowLeft, ArrowRight } from './styles';

const Previous = () => (
	<ArrowContainer>
		<ArrowLeft />
		<span>Anterior</span>
	</ArrowContainer>
);

const Next = () => (
	<ArrowContainer>
		<span>Próxima</span>
		<ArrowRight />
	</ArrowContainer>
);

const Pagination = ({ paginateTo, pageCount, pageSize, currentPage }) => {
	return (
		<Container>
			<ReactPaginate
				previousLabel={<Previous />}
				nextLabel={<Next />}
				breakLabel={<span className='gap'>...</span>}
				pageCount={pageCount}
				forcePage={Number(currentPage)}
				onPageChange={({ selected }) => paginateTo(selected)}
				containerClassName={'__pagination'}
				disabledClassName={'disabled'}
				activeClassName={'active'}
			/>
		</Container>
	);
};

export default Pagination;
