import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button';
import { Heading3 } from '../Typography';
import {
	Container,
	ContainerHeader,
	Prices,
	GenreContainer,
	Description,
	MinInfos,
	RadioInfos,
	KilometersIndicator,
} from './styles';

import { toCurrencyFormat } from '../../utils/helpers/strings';
import { renderLocaleWeeklySchedule } from '../../utils/helpers/others';

import { diff } from '../../utils/helpers/dates';

const Opportunity = ({ data }) => {
	const [numberOfVoices, setNumberOfVoices] = useState(0);

	useEffect(() => {
		const { script } = data;

		if (script) {
			let voicesCount = 0;

			script.body.forEach((item) => {
				if (item.value.toUpperCase().includes('VOZ')) {
					voicesCount = voicesCount + 1;
				}
			});

			setNumberOfVoices(voicesCount);
		}
	}, [data]);

	return (
		<Container isValid={data?.distance.isValid} onClick={() => console.log(data)}>
			<ContainerHeader>
				<div className='title'>
					{data?.distance.isValid ? (
						<Link to={{ pathname: `/opportunities/${data?._id}` }}>
							<Heading3>{data?.title}</Heading3>
						</Link>
					) : (
						<Heading3>{data?.title}</Heading3>
					)}

					<p>Publicado {diff(data?.createdAt)}</p>

					<Prices isValid={data?.distance.isValid}>
						<strong>{toCurrencyFormat(data?.prices?.min / 100)}</strong>
						<span>~</span>
						<strong>{toCurrencyFormat(data?.prices?.max / 100)}</strong>
					</Prices>
				</div>
				{data?.distance.isValid ? (
					<Link to={{ pathname: `/opportunities/${data?._id}` }}>
						<Button>Ver Detalhes</Button>
					</Link>
				) : (
					<KilometersIndicator>
						{data?.distance.kilometers === 0
							? 'Você possui um contrato vigente nesta cidade'
							: data?.distance.kilometers === -1
							? `Você possui um contrato com essa rádio`
							: `Você possui um contrato há ${data?.distance.kilometers}Km dessa oportunidade`}
					</KilometersIndicator>
				)}
			</ContainerHeader>
			<GenreContainer>
				<span>{data?.program?.genreId?.name}</span>
			</GenreContainer>
			<Description className='desc'>{data?.description}</Description>
			<MinInfos>
				<div className='info'>
					<strong>Duração do contrato: </strong>
					<p>{data.duration} meses</p>
				</div>
				<div className='info'>
					<strong>No ar:</strong>
					<p>{renderLocaleWeeklySchedule(data?.weeklySchedule)}</p>
				</div>
				<div className='info'>
					<strong>Número de Vozes:</strong>
					<p>{numberOfVoices} vozes</p>
				</div>
				<div className='info'>
					<strong>Candidatos:</strong>
					<p>{data?.candidates} interesados</p>
				</div>
			</MinInfos>
			<RadioInfos>
				<p className='radio-name'>{data?.user?.radioName}</p>
				<p className='radio-address'>
					{data?.user?.city} - {data?.user?.state}
				</p>
			</RadioInfos>
		</Container>
	);
};

export default Opportunity;
