import './collapse.css';

import React, { useState, useContext, useRef } from 'react';
import Recdal from 'recdal';
import { Collapse } from 'react-collapse';
import { FiEdit, FiTrash } from 'react-icons/fi';

import { IconButton } from '../Button';

import { AuthContext } from '../../store/Auth';

import { diff } from '../../utils/helpers/dates';
import { AnswersContainer, Answer, collapseTheme } from './styles';

const Answers = ({ data }) => {
	const { user } = useContext(AuthContext);
	const editModalRef = useRef(null);

	const [collapse, setCollapse] = useState(true);

	const handleToggleAnswersVisibility = () => setCollapse(!collapse);

	if (!data.length) {
		return null;
	}

	return (
		<>
			<AnswersContainer>
				<button onClick={handleToggleAnswersVisibility}>
					{collapse ? 'Mostrar' : 'Esconder'} {data.length}{' '}
					{data.length === 1 ? 'resposta' : 'respostas'}
				</button>
				<Collapse isOpened={!collapse} theme={collapseTheme}>
					<div className='answers-list'>
						{data.map((answer) => {
							const username =
								answer.user.userModel === 'casters'
									? `${answer.user.name} ${answer.user.surname}`
									: answer.user.radioName;

							return (
								<Answer key={answer._id}>
									<div>
										<strong timestamp={` • ${diff(answer.createdAt)}`}>
											{username}
										</strong>
										<p>{answer.content}</p>
									</div>

									{user._id === answer?.user?._id && (
										<div className='actions'>
											<IconButton
												title='Editar'
												icon={FiEdit}
												onClick={() => editModalRef.current.open()}
												variant='outlined'
											/>
											<IconButton
												// onClick={() => removeProposalModalRef.current.open()}
												title='Excluir'
												icon={FiTrash}
												variant='outlined'
											/>
										</div>
									)}
								</Answer>
							);
						})}
					</div>
				</Collapse>
			</AnswersContainer>

			<Recdal ref={editModalRef}>
				<h1>EDITARRRR RESP</h1>
			</Recdal>
		</>
	);
};

export default Answers;
