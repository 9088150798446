import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 850px;
	background: var(--white);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	border-radius: var(--border-radius-1);
	max-width: 90vw;
	max-height: 98vh;
	display: flex;
	flex-direction: column;

	& > header {
		width: 100%;
		padding: 12px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--input-border-hover);

		& > h2 {
			margin: 0;

			& > span {
				color: var(--body-1);
				opacity: 0.9;
			}
		}
	}

	& > div.content {
		overflow-y: auto;

		::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: var(--border-radius-3);
		}

		::-webkit-scrollbar-thumb {
			background: #d4d4d4;
			border-radius: var(--border-radius-3);
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: #929292;
		}

		& > form {
			width: calc(100% - (24px * 2));
			margin: 24px;
			margin-bottom: 0;
		}

		& > div.upload-area {
			width: 100%;
			display: flex;
			padding: 24px;

			@media (max-width: 745px) {
				flex-direction: column;
			}
		}

		@media (max-width: 745px) {
			padding-bottom: 24px;
		}
	}

	& > div.buttons {
		border-top: 1px solid var(--input-border);
		grid-column: span 4;
		padding: 6px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 24px;

		& > button ~ button {
			margin-left: 8px;
		}

		@media (max-width: 745px) {
			margin-top: 0;
		}
	}
`;

export const Helper = styled.div`
	padding: 0 24px;
	margin-top: 24px;

	& > p {
		font-size: 15px;
		color: var(--body-2);

		& > strong {
			margin-right: 4px;
		}

		& > span {
			font-style: italic;
		}
	}
`;

export const DailyData = styled.div`
	width: 100%;
	padding: 24px 24px 0 24px;
	display: flex;
	flex-direction: column;

	& > div.loading {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		justify-content: center;
		border: none;
		width: 100%;

		& > p {
			font-size: 14px;
			font-weight: 600;
			margin-left: 12px;
			color: var(--primary);
		}
	}

	& > ul {
		padding: 0 24px;

		& > li {
			font-size: 15px;
			color: var(--body-1);
			font-weight: 600;
			display: flex;
			align-items: center;

			& > svg {
				font-size: 20px;
				color: var(--primary);
				transform: translateX(-16px);
			}

			& > svg.disabled {
				color: var(--body-2);
				opacity: 0.8;
			}

			& > button {
				font-size: 15px;
				border: none;
				background: none;
				cursor: pointer;
				margin: 0 4px;
				color: var(--primary);

				&:hover {
					text-decoration: underline;
				}
			}
		}

		& > li ~ li {
			margin-top: 8px;
		}
	}
`;

export const Column = styled.div`
	width: 50%;

	@media (max-width: 911px) {
		width: auto;

		${({ right }) =>
			right &&
			css`
				width: 60%;
			`}

		${({ left }) =>
			left &&
			css`
				width: 40%;
			`}
	}

	@media (max-width: 745px) {
		width: 100%;
		padding-left: 0;
		padding-right: 0;

		${({ right }) =>
			right &&
			css`
				padding-top: 12px;
			`}

		${({ left }) =>
			left &&
			css`
				padding-bottom: 12px;
			`}
	}

	${({ right }) =>
		right &&
		css`
			padding-left: 12px;
		`}

	${({ left }) =>
		left &&
		css`
			padding-right: 12px;
		`}
`;

export const UploadZone = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`;

export const UploadContainer = styled.label`
	position: relative;
	border: 1px dashed var(--input-border-hover);
	cursor: pointer;
	height: calc((60px * 4) + (4px * 3)); /* height of 4 UploadItem's */
	width: 100%;
	border-radius: var(--border-radius-1);
	transition: 300ms;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&:hover {
		background: var(--input-focus);
	}

	& > svg {
		font-size: 60px;
		color: var(--heading-1);
		opacity: 0.2;
		pointer-events: none;
		margin-bottom: 16px;
		transition: 300ms;
	}

	& > span {
		font-size: 20px;
		margin-bottom: 2px;
		color: var(--body-2);
		opacity: 0.5;
		font-weight: 600;
		transition: color 150ms;
		pointer-events: none;
		text-align: center;
	}

	& > input {
		display: none;
	}

	@media (max-width: 745px) {
		height: 120px;

		& > svg {
			margin-bottom: 12px;
		}
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
			opacity: 0.6;

			& * {
				pointer-events: none !important;
			}
		`}
`;

export const ProgressBarWrapper = styled.div`
	width: 100%;
	padding: 0 24px 24px 24px;
`;

export const PercentageBar = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
	padding: 0 24px;

	& > div.text {
		width: 100%;
		margin-bottom: 6px;
		display: flex;
		align-items: center;

		& > p {
			color: var(--body-2);
			font-size: 15px;
			font-weight: 600;

			& > strong {
				color: var(--body-1);
			}
		}
	}

	& > div.percentage {
		position: relative;
		height: ${({ percentage }) => (percentage === 100 ? '22px' : '14px')};
		width: 100%;
		background: var(--success-opacity);
		border-radius: var(--border-radius-3);

		& > div {
			top: 0;
			left: 0;
			height: 100%;
			position: absolute;
			background: var(--success);
			border-radius: var(--border-radius-3);
			width: ${({ percentage }) => percentage}%;
			transition: width 500ms;
			transition-delay: 150ms;
		}
	}
`;

export const PercentageIndicator = styled.span`
	font-size: ${({ percentage }) => (percentage === 100 ? '14px' : '8px')};
	color: ${({ percentage }) => (percentage === 100 ? 'var(--white)' : 'var(--success)')};
	position: absolute;
	top: 50%;
	right: ${({ percentage }) => (percentage === 100 ? '50%' : '0%')};
	transform: ${({ percentage }) =>
		percentage === 100 ? 'translate(50%, -50%)' : 'translate(-50%, -50%)'};
	font-weight: 600;
	display: flex;
	align-items: center;
	transition: 500ms;
	transition-timing-function: cubic-bezier(1, 0, 0, 1);

	${({ percentage }) =>
		percentage === 100 &&
		css`
			width: 100%;
			justify-content: center;
		`};

	& > svg {
		font-size: 15px;
		margin-left: 6px;
	}

	@media (max-width: 350px) {
		font-size: ${({ percentage }) => (percentage === 100 ? '12px' : '8px')};
	}
`;

export const EmptyList = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--body-2);
	font-size: 18px;
	opacity: 0.4;
`;

export const UploadsList = styled.ul`
	width: 100%;
	max-height: calc((60px * 4) + (4px * 3));
	height: max-content;
	overflow-y: auto;
	padding-right: 4px;

	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: var(--border-radius-3);
	}

	::-webkit-scrollbar-thumb {
		background: #d4d4d4;
		border-radius: var(--border-radius-3);
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #929292;
	}

	@media (max-width: 745px) {
		max-height: max-content;
		overflow: hidden;
	}
`;

export const UploadItem = styled.li`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 4px;
	border-radius: var(--border-radius-1);
	transition: background-color 300ms;
	border: 1px solid #f1f1f1;

	& ~ li {
		margin-top: 4px;
	}

	&:hover {
		background: #f7f7f5;
	}

	& > div.content {
		height: 100%;
		display: flex;
		align-items: center;
		flex-grow: 1;
		width: 75%;

		& > div.text {
			height: 100%;
			padding: 8px;
			display: flex;
			flex-direction: column;
			flex: 1;

			& > p {
				font-size: 15px;
				color: var(--body-1);
				font-weight: 600;
				margin-bottom: 2px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 95%;
			}

			& > span {
				font-size: 12px;
				color: var(--body-2);
			}
		}
	}

	& > div.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 25%;

		& > button ~ button {
			margin-left: 8px;
		}
	}

	@media (max-width: 511px) {
		flex-direction: column;

		& > div {
			width: 100% !important;
		}
	}
`;

export const Icon = styled.div`
	padding: 6px;
	margin-right: 16px;

	& svg {
		font-size: 24px;
		color: var(--body-1);
	}

	@media (max-width: 511px) {
		transform: translateY(34%);
	}
`;

export const UploadedFileIcon = styled.button`
	border: none;
	background: none;
	padding-right: 4px;
	cursor: pointer;

	& > svg {
		font-size: 20px;
		color: var(--body-2);

		&:hover {
			color: ${({ isPlayer }) => (isPlayer ? 'var(--primary)' : 'var(--danger)')};
		}
	}
`;

export const Volume = styled.input`
	background: var(--primary);
	-webkit-appearance: none;
	appearance: none;
	width: 50px;
	height: 10px;
	transform: rotate(-90deg);
	border-radius: 6px;
	background: var(--grey-1);
	outline: none;
	-webkit-transition: 0.2s;
	transition: height 150ms;
	opacity: ${({ isPlaying }) => (isPlaying ? 1 : 0)};
	pointer-events: ${({ isPlaying }) => (isPlaying ? 'all' : 'none')};

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		border-radius: 100%;
		width: 14px;
		height: 14px;
		background: var(--primary);
		cursor: pointer;
	}

	@media (max-width: 511px) {
		transform: rotate(0deg);
		margin-right: 16px;
	}
`;
