export const emojis = [
	'😀',
	'😃',
	'😄',
	'😁',
	'😆',
	'😅',
	'🤣',
	'😂',
	'🙂',
	'🙃',
	'😉',
	'😊',
	'😇',
	'🥰',
	'😍',
	'🤩',
	'😘',
	'😗',
	'😚',
	'😙',
	'😋',
	'😛',
	'😜',
	'🤪',
	'😝',
	'🤑',
	'🤗',
	'🤭',
	'🤫',
	'🤔',
	'🤐',
	'🤨',
	'😐',
	'😑',
	'😶',
	'😏',
	'😒',
	'🙄',
	'😬',
	'🤥',
	'😌',
	'😔',
	'😪',
	'🤤',
	'😴',
	'😷',
	'🤒',
	'🤕',
	'🤢',
	'🤮',
	'🤧',
	'🤠',
	'🥳',
	'😎',
	'🤓',
	'🧐',
	'😕',
	'😟',
	'🙁',
	'😮',
	'😯',
	'😲',
	'😳',
	'🥺',
	'😦',
	'😧',
	'😨',
	'😰',
	'😥',
	'😢',
	'😭',
	'😱',
	'😖',
	'😣',
	'😞',
	'😓',
	'😩',
	'😫',
	'🥱',
	'😤',
	'😡',
	'❣',
	'💔',
	'❤',
	'🧡',
	'💛',
	'💚',
	'💙',
	'💜',
	'📯',
	'🔔',
	'🔕',
	'🎼',
	'🎵',
	'🎶',
	'🎙',
	'🎚',
	'🎛',
	'🎤',
	'🎧',
	'📻',
	'🎷',
	'🎸',
	'🎹',
	'🎺',
	'🎻',
	'🪕',
	'🥁',
	'📱',
	'📲',
	'☎',
	'📞',
	'‼',
	'⁉',
	'❓',
	'❔',
	'❕',
	'❗',
	'〰',
	'💱',
	'💲',
	'⚕',
	'♻',
	'⚜',
	'🔱',
	'📛',
	'🔰',
	'⭕',
	'✅',
	'☑',
	'✔',
	'❌',
	'❎',
	'🟠',
	'🟡',
	'🟢',
	'🔵',
	'🟣',
	'🟤',
	'⚫',
	'⚪',
	'🟥',
	'🟧',
	'🟨',
	'🟩',
	'🟦',
	'🟪',
	'🟫',
	'⬛',
	'⬜',
];
