import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import {
	Container,
	Button,
	MenuContainer,
	MenuItem as StyledMenuItem,
	Divider as StyledDivider,
} from './styles';

import { BiDotsVerticalRounded } from 'react-icons/bi';

const OverflowMenu = ({ defaultOpen, children }) => {
	const containerRef = useRef(null);
	const menuRef = useRef(null);

	const [isVisible, setIsVisible] = useState(defaultOpen);
	const [position, setPosition] = useState({ top: '', left: '' });

	const handleToggleMenu = useCallback(() => {
		setIsVisible(!isVisible);
	}, [isVisible]);

	const closeMenu = useCallback(() => {
		if (!isVisible) {
			return;
		}

		setIsVisible(false);
	}, [isVisible]);

	useLayoutEffect(() => {
		if (isVisible) {
			let top;
			let left;

			const menu = menuRef.current;
			const container = containerRef.current;

			const menuProperties = menu.getBoundingClientRect();
			const containerProperties = container.getBoundingClientRect();

			const containerOffsetLeft = containerProperties.left;
			const containerOffsetTop = containerProperties.top;

			const menuWidth = menuProperties.width;
			const menuHeight = menuProperties.height;
			const viewHeight = document.documentElement.clientHeight;

			if (containerOffsetLeft <= menuWidth) {
				left = '50%';
			} else {
				left = `calc(50% - ${menuWidth}px)`;
			}

			if (containerOffsetTop + menuHeight >= viewHeight) {
				top = `-${menuHeight}px`;
			} else {
				top = '100%';
			}

			return setPosition({ top, left });
		}
	}, [isVisible]);

	return (
		<Container ref={containerRef}>
			<Button onClick={handleToggleMenu}>
				<BiDotsVerticalRounded />
			</Button>
			{isVisible && (
				<OutsideClickHandler disabled={!isVisible} onOutsideClick={closeMenu}>
					<MenuContainer
						ref={menuRef}
						top={position.top}
						left={position.left}
						onClick={closeMenu}>
						{children}
					</MenuContainer>
				</OutsideClickHandler>
			)}
		</Container>
	);
};

const MenuItem = ({ children, disabled, isDanger, icon: Icon, ...rest }) => (
	<StyledMenuItem disabled={disabled} isDanger={isDanger} {...rest}>
		{Icon && <Icon />}
		{children}
	</StyledMenuItem>
);

const Divider = ({ isDanger }) => (
	<StyledDivider isDanger={isDanger}>
		<span></span>
	</StyledDivider>
);

export { MenuItem, Divider };
export default OverflowMenu;
