import styled from 'styled-components';

export const Heading1 = styled.h1`
    font-size: 32px;
    color: var(--heading-1);
    margin-bottom: 16px;
`;

export const Heading2 = styled.h2`
    font-size: 25px;
    color: var(--heading-1);
    margin-bottom: 16px;
`;

export const Heading3 = styled.h3`
    font-size: 20px;
    color: var(--heading-1);
    margin-bottom: 16px;
    opacity: .9;
`;