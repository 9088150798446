import styled from 'styled-components';

export const Container = styled.div`
	width: auto;
	height: auto;
	background: #fff;
	display: grid;
	grid-template-columns: repeat(10, 35px);
	grid-auto-columns: auto;
	grid-auto-rows: auto;
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-100%, -100%);
	max-height: calc(6 * 35px);
	padding: 8px;
	border-radius: var(--border-radius-1);
	border: 1px solid var(--input-border);
	box-shadow: 0px 4px 5px 2px rgba(200, 200, 200, 0.1);

	overflow-y: auto;

	/* width */
	::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: var(--grey-1);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--body-2);
		border-radius: 2px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: var(--body-1);
	}
`;

export const ToggleButton = styled.button`
	width: 30px;
	height: 30px;
	min-width: 30px;
	min-height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	cursor: pointer;
	transition: background-color 300ms;
	position: absolute;

	bottom: ${({ bottom }) => bottom || '12px'};
	right: ${({ right }) => right || '12px'};

	z-index: 2;

	&:hover {
		background: var(--grey-1);
	}

	& svg {
		font-size: 22px;
		color: var(--body-2);
		opacity: 0.6;
	}
`;

export const Emoji = styled.div`
	height: 35px;
	grid-column: span 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 300ms;
	&:hover {
		background: var(--grey-1);
	}
	/* border: 1px solid var(--grey-1); */
`;
