import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';

import Button from '../Button';
import { notify } from '..//Notification';
import { Container, Blocks, Block, Tracks, Track, Player } from './styles';

import { GET } from '../../services/api';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';

const PlaylistVisualization = ({ rows }) => {
	const player = useRef(null);

	const [fallback, setFallback] = useState({ fetchgingTrack: null });
	const [playingTrackId, setPlayingTrackId] = useState(null);

	const blocks = useMemo(() => {
		if (!rows) return null;

		const _blocks = [];
		const allTracks = rows.slice(6, rows.length).map((row) => [row[2], row[3], row[4]]);

		allTracks.forEach((row) => {
			const isNewBlock = row[0].startsWith('BLOCO ') && !row[1] && !row[2];

			if (isNewBlock) {
				_blocks.push({
					name: `BLOCO #${_blocks.length + 1}`,
					tracks: [],
				});
			} else {
				_blocks[_blocks.length - 1].tracks.push(row);
			}
		});

		return _blocks;
	}, [rows]);

	const handlePlaySong = useCallback(async (song) => {
		try {
			setFallback((prev) => ({ ...prev, fetchgingTrack: song?.id }));
			setPlayingTrackId(song?.id);

			song.artist = song.artist.replace('&', '|');
			song.name = song.name.replace('&', '|');

			const { data: dbTracks } = await GET(
				`api/v2/tracks?artist=${song?.artist}&name=${song?.name}&active=true`
			);

			if (dbTracks.total) {
				player.current.src = resolveFileSrc({ fileName: dbTracks.tracks[0]?.filename });
				return player.current.play();
			}

			/**
			 * Música não foi encontrada no banco.
			 * Busca dentre as músicas do usuário */
			const { data: userTracks } = await GET(
				`api/v2/tracks?artist=${song?.artist}&name=${song?.name}&active=false`
			);

			if (userTracks.total) {
				player.current.src = resolveFileSrc({ fileName: userTracks.tracks[0]?.filename });
				player.current.play();
			}

			return notify('error', 'Não foi possível reproduzir a música');
		} catch (error) {
			console.error(error);
		} finally {
			setFallback((prev) => ({ ...prev, fetchgingTrack: false }));
		}
	}, []);

	const handleStopPlayer = useCallback(() => {
		player.current.ref = undefined;
		player.current.pause();

		setPlayingTrackId(null);
	}, []);

	useEffect(() => {
		return () => {
			handleStopPlayer();
		};
	}, [handleStopPlayer]);

	return (
		<Container>
			<Player ref={player} />

			<Blocks>
				{blocks.map((block, blockIndex) => (
					<Block key={blockIndex}>
						<header>
							<p>{block.name}</p>
						</header>

						<Tracks>
							<tbody>
								{block.tracks.map(([name, artist], trackIndex) => (
									<Track key={trackIndex}>
										<td>
											<span className='index'>#{trackIndex + 1}</span>
										</td>
										<td>
											<strong>{artist}</strong>
										</td>
										<td>
											<p>{name}</p>
										</td>
										<td>
											<div
												className='actions'
												style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
												<Button
													size='small'
													variant={
														playingTrackId === `${blockIndex}-${trackIndex}`
															? 'outlined'
															: 'default'
													}
													style={{ width: '120px' }}
													onClick={() => {
														playingTrackId === `${blockIndex}-${trackIndex}`
															? handleStopPlayer()
															: handlePlaySong({
																	artist,
																	name,
																	id: `${blockIndex}-${trackIndex}`,
															  });
													}}
													loading={
														fallback?.fetchgingTrack &&
														playingTrackId === `${blockIndex}-${trackIndex}`
													}
													disabled={
														fallback?.fetchgingTrack &&
														fallback?.fetchgingTrack !== `${blockIndex}-${trackIndex}`
													}>
													{fallback?.fetchgingTrack &&
													playingTrackId === `${blockIndex}-${trackIndex}` ? (
														<span style={{ paddingLeft: 4 }}>Carregando...</span>
													) : playingTrackId === `${blockIndex}-${trackIndex}` ? (
														<>Parar</>
													) : (
														<>Reproduzir</>
													)}
												</Button>
											</div>
										</td>
									</Track>
								))}
							</tbody>
						</Tracks>
					</Block>
				))}
			</Blocks>
		</Container>
	);
};

export default PlaylistVisualization;
