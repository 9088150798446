import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Notification = styled(ToastContainer)`
	.Toastify__toast {
		border-radius: var(--border-radius-1) !important;
        padding: 0 24px;
        width: max-content;

        & > button {
            margin: auto 0;
        }
	}

	.Toastify__toast--success {
		background: var(--success);
	}
	.Toastify__toast--warning {
		background: var(--primary);
	}
	.Toastify__toast--error {
		background: var(--danger);
	}
`;

export const NotificationContent = styled.span`
	color: var(--white);
	display: flex;
	align-items: center;
    margin-right: 24px;

	& > svg {
		margin-right: 16px;
		font-size: 20px;
	}
`;
