/**
 * Padronizar zip & zipCode
 */

import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';
import * as Yup from 'yup';
import Avatar from 'react-avatar';

import { GET, PUT, PATCH } from '../../services/api';
import { AuthContext } from '../../store/Auth';

import Fallback from '../../components/Fallback';
import Breadcumbs from '../../components/Breadcumbs';
import Button from '../../components/Button';
import { Heading1 } from '../../components/Typography';
import { Form, TextArea, Input, Select, MaskedInput } from '../../components/Form';
import {
	Container,
	FormContainer,
	FilesFormWrapper,
	Player,
	AvatarWrapper,
	PlayerHeader,
	PlayerFooter,
	Divider,
} from './styles';

import { IoPlayCircle, IoPauseCircle } from 'react-icons/io5';
import { BiEditAlt, BiUserVoice } from 'react-icons/bi';

import { brazilianStates } from '../../constants';
import { notify } from '../../components/Notification';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';

const breadcumbsStack = [
	{ label: 'Início', pathname: '/' },
	{ label: 'Perfil', pathname: null },
];

const Profile = () => {
	const bodyFormRef = useRef(null);
	const { user, signIn } = useContext(AuthContext);

	const [profile, setProfile] = useState({});
	const [fallback, setFallback] = useState('initial-data');
	const [state, setState] = useState('');
	const [profilePic, setProfilePic] = useState(null);
	const [audioDemo, setAudioDemo] = useState(null);

	const handleSubmit = useCallback(
		async (formData) => {
			try {
				formData.state = state;
				formData.zipCode = formData.zipCode.replace(/\D+/g, '');

				const validationSchema = Yup.object().shape({
					name: Yup.string().required('Informe seu nome'),
					surname: Yup.string().required('Informe seu sobrenome'),
					email: Yup.string().required('Informe seu email'),
					zipCode: Yup.string().required('Informe seu CEP'),
					city: Yup.string().required('Informe sua cidade'),
					state: Yup.string().required('Informe seu estado'),
					mic: Yup.string().required('Informe o modelo do seu microfone'),
					soundcard: Yup.string().required('Informe o modelo da sua placa de som'),
					bio: Yup.string().required('Fale algo legal sobre você'),
					phone: Yup.string().required('Informe seu WhatsApp'),
				});

				await validationSchema.validate(formData, { abortEarly: false });

				setFallback('updating');
				bodyFormRef.current.setErrors({});

				const {
					data: { caster: updatedCaster },
				} = await PUT(`/api/v2/casters/${user._id}`, formData);

				delete updatedCaster.audioDemo;
				delete updatedCaster.profilePic;

				if (profilePic) {
					const payload = new FormData();
					payload.append('profilePic', profilePic);

					const {
						data: { caster },
					} = await PATCH(`/api/v2/casters/${user._id}/profilePic`, payload);

					updatedCaster.profilePic = caster.profilePic;
				}

				if (audioDemo) {
					const payload = new FormData();
					payload.append('audioDemo', audioDemo);

					const {
						data: { caster },
					} = await PATCH(`/api/v2/casters/${user._id}/audioDemo`, payload);

					updatedCaster.audioDemo = caster.audioDemo;
				}

				setFallback(null);
				setProfilePic(null);
				setAudioDemo(null);

				signIn({
					user: { ...user, ...updatedCaster },
					token: sessionStorage.getItem('@AUTH'),
					persistLogged: false,
					redirect: false,
				});

				return notify('success', 'Perfil atualizado');
			} catch (error) {
				setFallback(null);
				console.error(error);

				if (error instanceof Yup.ValidationError) {
					const errors = {};

					error.inner.forEach(({ path, message }) => (errors[path] = message));
					return bodyFormRef.current.setErrors(errors);
				}
			}
		},
		[state, user, signIn, profilePic, audioDemo]
	);

	const handleFileInputChange = useCallback((setter, event, accept) => {
		const file = event.target.files[0];

		if (!file) {
			return setter(null);
		}

		if (!accept.some((ext) => file.type.includes(ext))) {
			setter(null);
			return notify('warn', 'Tipo de arquivo incorreto');
		}

		setter(file);
		return notify('success', 'Arquivo selecionado, clique em Salvar para fazer o upload');
	}, []);

	useEffect(() => {
		setFallback(true);

		const fetchData = async () => {
			try {
				const {
					data: { caster },
				} = await GET(`/api/v2/casters/${user._id}`);

				setProfile(caster);
				setState(caster.state);
				setFallback(null);
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, [user]);

	if (fallback === 'initial-data') {
		return <Fallback />;
	}

	return (
		<Container>
			<Breadcumbs stack={breadcumbsStack} />
			<Heading1 onClick={() => console.log(audioDemo)}>Perfil</Heading1>

			<FormContainer>
				<FilesFormWrapper>
					<AvatarWrapper>
						<Avatar
							round
							size={180}
							src={
								profilePic
									? URL.createObjectURL(profilePic)
									: resolveFileSrc({ folder: ['profile_pics'], fileName: user.profilePic })
							}
							name={`${user.name} ${user.surname}`}
						/>

						<label htmlFor='profile-pic'>
							<BiEditAlt />
							<p>Trocar Imagem</p>
						</label>

						<input
							type='file'
							id='profile-pic'
							accept='.jpeg, .jpg, .png, .gif'
							onChange={(e) => handleFileInputChange(setProfilePic, e, ['image'])}
						/>
					</AvatarWrapper>

					<Divider />

					<Player
						controls
						src={
							audioDemo
								? URL.createObjectURL(audioDemo)
								: resolveFileSrc({ folder: ['casters_demo'], fileName: user.audioDemo })
						}
						showJumpControls={false}
						autoPlayAfterSrcChange={false}
						customIcons={{
							play: <IoPlayCircle />,
							pause: <IoPauseCircle />,
						}}
						header={
							<PlayerHeader>
								<span>Audio demo</span>
								<BiUserVoice />
							</PlayerHeader>
						}
						footer={
							<PlayerFooter>
								<label htmlFor='audio-demo'>Alterar</label>
								<input
									type='file'
									id='audio-demo'
									accept='.mp3'
									onChange={(e) => {
										handleFileInputChange(setAudioDemo, e, ['audio']);
									}}
								/>
							</PlayerFooter>
						}
					/>
				</FilesFormWrapper>
				<Form ref={bodyFormRef} columns='1fr 1fr' onSubmit={handleSubmit} initialData={profile}>
					<Input name='name' label='Nome' />
					<Input name='surname' label='Sobrenome' />
					<Input name='email' label='Email' />
					<MaskedInput name='zipCode' mask='99999-999' label='CEP' />
					<MaskedInput name='phone' mask='(99) 9 9999-9999' label='WhatsApp' />
					<Input name='city' label='Cidade' />
					<Select
						name='state'
						label='Estado'
						onChange={({ value }) => setState(value)}
						options={brazilianStates}
					/>
					<Input name='mic' label='Microfone' />
					<Input name='soundcard' size={2} label='Placa de Som' />
					<TextArea name='bio' label='Bio' size={2} rows={4} />
					<Button
						loading={fallback === 'updating'}
						type='submit'
						color='success'
						style={{ gridColumnStart: 2 }}>
						Atualizar Perfil
					</Button>
				</Form>
			</FormContainer>
		</Container>
	);
};

export default Profile;
