import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	& .react-tabs__tab {
		background: transparent;
		color: var(--body-1);
        padding: 12px;

		&:hover {
			color: var(--primary);
		}
	}

	& .react-tabs__tab--selected {
		border: none;
		border-bottom: 4px solid var(--primary);
		color: var(--primary);
		font-weight: 600;

		&:hover {
			color: var(--primary);
		}
	}

	& .react-tabs__tab-list {
		border-bottom-color: #dedede;
	}

	& .react-tabs__tab-panel {
		padding: 0 16px 12px 16px;
	}
`;
